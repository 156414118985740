import React, { useEffect, useState } from "react"
import { Button,message , DatePicker, Space } from 'antd';
import { } from 'antd';
import moment from "moment";
import classnames from "classnames";

const { RangePicker } = DatePicker;
//自定义查询组件

interface Props {
  confirm: Function,
  active: Boolean
}
export const CustomSelectPanel = (props: Props) => {
  const [timeRange, setTimeRange] = useState(null)
  const [panelStatus, setPanelStatus] = useState(false)
  const [timeRangeStr, setTimeRangeStr] = useState('')

   useEffect(()=>{
      setTimeRange(null)
   },[props.active])
  
  const handleClick = () => setPanelStatus(!panelStatus)

  const handleConfirm = () => {
    if (!timeRange) {
      message.error('请输入日期');
      return false
    }
    props.confirm(timeRange)
    setTimeRangeStr(`${timeRange.beginTime}-${timeRange.endTime}`)
    setPanelStatus(false)
  }

   
  const handleCancel = () => {
    setPanelStatus(false)
  }
  const handleChangeTimeRange = (times: Array<any>) => {
    setTimeRange({ beginTime: moment(times[0]._d).format('YYYY/MM/DD'), endTime: moment(times[1]._d).format('YYYY/MM/DD') })
  }

  /**
   * 第一步 点击确定能够查询
   */
  const panelContent = <div className='custom-select-panel'>

    <RangePicker style={{ marginLeft: '10px' }} onChange={(e) => handleChangeTimeRange(e)} />
    <Button
      onClick={handleConfirm}
      type='primary'
      style={{ marginLeft: "10px", marginRight: "10px" }}>
      确定
    </Button>
    <Button onClick={handleCancel}>取消</Button>
  </div>
  return <div style={{ position: "relative" }}>

    <div onClick={handleClick} className={classnames({
      'custom-button': true,
      'active': props.active
    })} >
      {props.active ? timeRangeStr : ' 自定义'}
    </div>
    {panelStatus ? panelContent : null}

  </div>
}

