import React, { useState } from 'react'
import { Layout, LineLayout } from '@/layout'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { Card } from '@/components/card'
import { MultipleLineChart } from '@/components/chart/multipleLineChart'
import { Border1 } from '@/components/border'
import { useRequest } from 'ahooks'
import { Call } from '@/api/request'
import { handleDataForMultipleLineChart, searchParams } from '@/utils'
import { Empty } from '@/components/empty'
import { Error } from '@/components/error'
import Loading from '@/components/loading/loading'
import { formatLineChartXAxis, getTimeRangeByUrl } from '@/utils/time'
import './andon.scss'


//将呼叫记录按照callType 分组、
const callTypeMap = {
  0: '设备',
  1: '质量',
  2: '人员',
  3: '物料'
}
const groupRecordsByCallType = (data: Array<any>) => {
  // Equipment，Quality，Human，Material 0 1 2 3
  let result = [{
    title: '设备',
    data: []
  }, {
    title: '质量',
    data: []
  }, {
    title: '物料',
    data: []
  }, {
    title: '人员',
    data: []
  }]
  data.forEach(item => {
    result[item.callType].data.push(item)
  })
  return result
}


// const groupCountdsByCallType = (timeRangeType: number, data: any) => {
//   let yAxis = [[], [], [], []];

//   data.forEach((item: any, idx: number) => {
//     yAxis[idx] = item.values.map((childItem: any) => { return Math.random() })
//   });


//   return {
//     yAxis: yAxis,
//     xAxis: formatLineChartXAxis(timeRangeType, data[0].values)
//   }
// }

const Andon = () => {
  const lineCode = searchParams().lineCode
  const toolbarIsVisiable = searchParams().toolbarIsVisiable
  const pageName = toolbarIsVisiable === 'false' ? '呼叫' : 'ZS-TEK智能化产线-呼叫'
  const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
  const [records, setRecords] = useState([{
    title: '设备',
    data: []
  }, {
    title: '质量',
    data: []
  }, {
    title: '物料',
    data: []
  }, {
    title: '人员',
    data: []
  }])

  //获取记录
  useRequest(() => { return Call.getCallingRecords(lineCode) }, {
    onSuccess: (data: any) => {
      setRecords(groupRecordsByCallType(data))
    },
    pollingInterval: 5 * 1000
  })


  //图表
  let rightContent: JSX.Element

  const { loading, data, error } = useRequest(() => {
    return Call.getCountByTimeRangeByCallType(lineCode, timeRange)
  }, { refreshDeps: [timeRange] })
  if (loading) {
    rightContent = <Loading />
  }
  if (error) {
    rightContent = <Error />
  }

  if (!loading) {
    rightContent = <MultipleLineChart
      id='call' legend={['设备', '质量', '物流', '人员']}
      data={handleDataForMultipleLineChart((timeRange as any).timeRangeType, data as any)}
      title=''
      height={850}
    />
  }

  return (
    <Layout name={pageName}>
      <LineLayout justify="start">
        {/* 左侧标签信息 */}
        <LineLayout direction="column" width={450} height={920} margin={[50, 30, 0, 30]} gap={30}>
          {
            records.map(item => {
              return <Card height={200} border={<Border1 />}>
                <div className="call-type">{item.title}<span>{item.data.length}</span></div>
                {
                  item.data.length === 0 ? <p className='list-empty' >无正在呼叫的记录</p> :
                    <ul >
                      {item.data.filter((item, idx) => idx < 3).map(item => {
                        return <li>产线{1}-{item.workStationName}工位-{callTypeMap[item.callType]}</li>
                      })}
                    </ul>
                }
              </Card>
            })
          }
        </LineLayout>
        {/* 右侧图标数据 */}
        <LineLayout direction="column" width="1550" height="920" margin={[25, 0, 0, 0]}>
          <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
          {rightContent}
        </LineLayout>
      </LineLayout>
    </Layout >
  )
}
export { Andon }
