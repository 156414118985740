import React, { Fragment } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './index.scss'
interface SwiperProps {
    urls: Array<string>
    name?: string,
    interval?: number
}
const onChange = () => { }
export const Swiper = (props: SwiperProps) => {

    return <Carousel showArrows={true} onChange={onChange} interval={props.interval} autoPlay infiniteLoop stopOnHover={true} swipeable={true}>
        {
            props.urls.map(item => {
                return <div className='carousel-item'>
                    <iframe src={item} height='100%' width='100%' />
                </div>
            })
        }

    </Carousel>

}