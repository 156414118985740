import React, { useState } from 'react'
import { LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import './index.scss'
import { useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import { Empty } from '@/components/empty'
import { Error } from '@/components/error'
import * as API from '@/api/request'
import Loading from '@/components/loading/loading'
interface Props {
  data?: Array<any>
  jumpTo?: string
}
const handleCounts = (data: Array<any>) => {
  //[Equipment，Quality，Human，Material]
  if (!!data[0] && data.length !== 0) {
    return data.map(((item: any) => {
      return {
        count: item.values[0].count,
        callType: item.callType
      }
    }))
  }
  return [
    { count: 0, callType: 0 },
    { count: 0, callType: 1 },
    { count: 0, callType: 2 },
    { count: 0, callType: 3 },
  ]
}
const handleRecord = (data: Array<any>) => {
  if (!!data && data.length !== 0) {
    return data.filter((item, idx) => idx < 5).map((item: any) => {
      return `产线${item.lineCode}-${item.workStationName}-${callTypeMap[item.callType]}`
    })
  }
  return []
}
const callTypeMap = {
  0: '设备',
  1: '质量',
  2: '人员',
  3: '物料'
}

let content = <Loading />
const Andon = (props: Props) => {
  const [lineCode] = useState(searchParams().lineCod) || null;
  const { data, loading, error } = useRequest(() => Promise.all(
    [
      API.Call.getCallingRecords(lineCode),
      API.Call.getTodayCallCount(lineCode)
    ]
  ), { pollingInterval: 1000 })

  if (error) {
    content = <Error />
    return <Error />
  }
  if (data) {
    const [callingRecords, todayCallCount]: Array<any> = data
    content = <React.Fragment>
      <LineLayout padding={[30, 30, 0, 30]}>
        {
          handleCounts(todayCallCount).map(item => <DigitalFlop count={item.count} title={callTypeMap[item.callType]} size={0.8} suffix="次" type='count' />)
        }
      </LineLayout>
      <LineLayout direction="column" padding={[30, 30, 0, 30]}>
        {
          handleRecord(callingRecords).length === 0 ? <Empty tip='' /> : handleRecord(callingRecords).map((item: any) => <div className="info-item">{item}</div>)
        }

      </LineLayout>
    </React.Fragment>
  }
  return  content
}
export { Andon }
