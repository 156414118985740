
import React, { useEffect, useState } from 'react'
import EZUIKit from 'ezuikit-js';
import qs from 'qs'
import { useMount, useUpdateEffect } from 'ahooks';
import axios from 'axios'
import service from '@/api/service';
import { reduceRight } from 'lodash';
interface VideoPlayerPops {
    width: number,
    height: number,
    url: string,
    margin?: string,
    token: string

}
const VideoPlayer = (props: VideoPlayerPops) => {
    useMount(() => {
        let player = new EZUIKit.EZUIKitPlayer({
            id: 'video-container', // 视频容器ID
            accessToken: props.token,
            url: props.url,
            audio: 0,
        })
        player.play();
    })

    const playerContainerStyles = {
        width: props.width + 'px',
        height: props.height + 'px',
        margin: props.margin,
    }
    return <div id='video-container' style={playerContainerStyles} />
}



export { VideoPlayer }