import React from 'react'
import './index.scss'

interface EmptyProps {
    tip?: string
    height?: number
}
export const Empty = (props: EmptyProps) => {

    return <div className='empty-content' style={{ height: props.height + 'px' }}>
        {
            (props.height && props.height < 60) ? null : <div className='empty-pic' />
        }

        <div className='tip'>{props.tip}</div>
    </div>
}
