import React, { Fragment, useEffect, useState } from 'react'
import { Layout, LineLayout, EasyGridLayout } from '@/layout'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { Border1 } from '@/components/border'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { LineChart } from '@/components/chart/lineChart'
import { useRequest } from 'ahooks'
import { Equipment } from '@/api/request'
import Loading from '@/components/loading/loading'
import { handleDataForLineChart, searchParams } from '@/utils'
import { getTimeRangeByUrl } from '@/utils/time'
import { store } from '@/store'
import { Card } from '@/components/card'
import { round } from 'lodash'
import FormatterType from '@/components/chart/formatterType'
const Oven = () => {
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    const [ovenLoading, setOvenLoading] = useState(true)
    const [chartLoading, setChartLoading] = useState(true)
    const [ovenWithTemperature, setOvenWithTemperature] = useState([])
    const [ovenElectricity, setOvenElectricity] = useState([])
    const [ovenOrRefrigerators] = useState(store.getState().ovenOrRefrigerators)
    const [hotElectricity, setHotElectricity] = useState([])
    const [coldElectricity, setColdElectricity] = useState([])
    //获取烘箱数据
    useRequest(() => { return Equipment.getOvensCurrentParameters(searchParams().lineCode) }, {
        pollingInterval: 10 * 1000,
        onSuccess: (data) => {
            //通过配置数据组装数据
            const ovenWithTemperature = ovenOrRefrigerators.map((item: any) => {
                let ovenWithTemperatureItem = data.find((oven: any) => oven.ovenCode === item.code)
                return {
                    ...item,
                    temperature: ovenWithTemperatureItem ? ovenWithTemperatureItem.temperatureInC : null,
                    power: ovenWithTemperatureItem ? ovenWithTemperatureItem.powerInW : null
                }
            })
            setOvenWithTemperature(ovenWithTemperature)
            setOvenLoading(false)

        }
    })

    useRequest(() => Equipment.getOvenElectricity(searchParams().lineCode, timeRange), {
        onSuccess: (data) => {
            setOvenElectricity(data)
            setColdElectricity(data.find((item: any) => item.ovenName === '冷定型').values)
            setHotElectricity(data.find((item: any) => item.ovenName === '热定型').values)
            //TODO:这个不知道为什么 后面再研究
            setTimeout(() => setChartLoading(false), 1000)
        },
        refreshDeps: [timeRange]
    })


    const changeTimeRange = (timeRange: any) => {
        setChartLoading(true)
        setTimeRange(timeRange)

    }
    const handleDataForOvenLineChart = () => {
        const ovenOrRefrigeratorsMap = {}
        ovenOrRefrigerators.forEach((item: any) => {
            ovenOrRefrigeratorsMap[item.code] = item.name
        })
        let result = { xAxis: [], series: [] }
        if (ovenElectricity.length === 0) return result
        result.xAxis = ovenElectricity[0].values.map((item: any) => item.timeRange.text)
        result.series = ovenElectricity.map((item, idx) => {
            return { name: ovenOrRefrigeratorsMap[item.ovenCode], data: item.values.map((childItem: any) => round(childItem.count, 1)) }
        })
        return result
    }

    return <Layout name="ZS-TEK产线-烘箱">
        {
            ovenLoading ? <Loading /> : <LineLayout>
                <EasyGridLayout width={850} height={920} gap={30} columns={2} rows={5} margin={[20, 0, 0, 0]} center>
                    {
                        ovenWithTemperature.map((item: any, idx: number) => {
                            return <Card border={<Border1 />} height={170} key={idx}>
                                <div style={{ color: '#fff', fontSize: '30px', padding: '40px 0 0 30px' }}>{item.name}</div>
                                < LineLayout justify='start' gap={80} padding={[0, 0, 0, 30]}>
                                    <DigitalFlop count={item.temperature} type='count' suffix='℃' />
                                    <DigitalFlop count={item.power} type='count' suffix='kW' color='orange' />
                                </LineLayout>
                            </Card>
                        })
                    }
                </EasyGridLayout>
                <LineLayout margin={[0, 0, 0, 0]} direction='column' width={1000}>
                    <TimePeriodSelector change={(timeRange: any) => { changeTimeRange(timeRange) }} />
                    {
                        chartLoading ? <Loading /> :
                            <Fragment>
                                <LineChart id='allOven' title='各烘箱能耗趋势' data={handleDataForOvenLineChart()} height={370} />
                                <LineLayout margin={[30, 0, 0, 0]}>
                                    <LineChart
                                        id='hot'
                                        title='热定型能耗趋势'
                                        height={370}
                                        data={handleDataForLineChart((timeRange as any).timeRangeType, coldElectricity)}
                                        formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} />
                                    <LineChart id='cold'
                                        title='冷定型能耗趋势'
                                        height={370}
                                        data={handleDataForLineChart((timeRange as any).timeRangeType, hotElectricity)}
                                        formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} />
                                </LineLayout>
                            </Fragment>
                    }
                </LineLayout >
            </LineLayout>
        }
    </Layout >


}
export { Oven }
