
//处理时刻数据
const handlePromptBoxTime = (time: any) => {
    if (time < 10) {
        return `0${time}:00`;
    } else {
        return `${time}:00`;
    }
}

const FormatterType = {

    //格式一: 时刻提示框
    todayMoment: (arg: any) => {
        return (
            handlePromptBoxTime(Number(arg[0].name)) +
            '-' +
            handlePromptBoxTime(Number(arg[0].name) + 1) +
            '<br />' +
            `<div style="font-weight: bold; text-align: right; margin-right: 5px;">${(arg[0].value === undefined ? '-' : arg[0].value)}</div>`
        )
    }
}

export default FormatterType;



