export default {
  backgroundColor: '#fff',
  legend: {
    data: ['计划时间段', '实际时间段'],
    align: 'left',
    left: 5,
    top: 5,
  },
  grid: {
    containLabel: true,
    show: false,
    right: 60,
    left: 5,
    bottom: 20,
    top: 30,
  },
  xAxis: {
    type: 'time',
    show: true,
    position: 'top',
    axisLabel: {
      show: true,
      interval: 'auto',
      formatter: '{yyyy}-{MM}-{dd}',
    },
    axisLine: {
      show: true,
    },
  },
  yAxis: {
    inverse: true,
    axisLabel: {
      show: true,
      interval: 'auto',
      formatter: function (value, index) {
        let last = ''
        let max = 5
        let len = value.length
        let hang = Math.ceil(len / max)
        if (hang > 1) {
          for (let i = 0; i < hang; i++) {
            let start = i * max
            let end = start + max
            let temp = value.substring(start, end) + '\n'
            last += temp //凭借最终的字符串
          }
          return last
        } else {
          return value
        }
      },
    },
    data: ['挡块', '托盘', '传感器预组装', '下单看板', '气排组件', '阀岛组件', '过度组件', '按钮盒安装'],
  },
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      let res = ''
      let title = ''
      let name = ''
      let start0 = ''
      let start = ''
      let end0 = ''
      let end = ''
      let titleNum = 0
      for (let i in params) {
        let k = i % 2
        if (!k) {
          //偶数
          start0 = new Date(params[i].data)
          start = start0.getFullYear() + '/' + (start0.getMonth() + 1) + '/' + start0.getDate()
        }
        if (k) {
          //奇数
          title = params[i].axisValue
          name = params[i].seriesName
          end0 = new Date(params[i].data)
          end = end0.getFullYear() + '/' + (end0.getMonth() + 1) + '/' + end0.getDate()
          res += !titleNum ? title + '</br>' : ''
          titleNum++
          res += name + ' : ' + end + '~' + start + '</br>'
        }
      }
      return res
    },
  },
  dataZoom: [
    {
      type: 'slider',
      show: undefined,
      yAxisIndex: [0],
      left: '98%',
      start: 0,
      end: 30,
    },
    {
      type: 'inside', // 内置滑动，随鼠标滚轮展示
      yAxisIndex: [0],
      start: 1, // 初始化时，滑动条宽度开始标度
      end: 100, // 初始化时，滑动条宽度结束标度
      zoomOnMouseWheel: false, // 如何触发缩放。可选值为：true：表示不按任何功能键，鼠标滚轮能触发缩放。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标滚轮能触发缩放。'ctrl'：表示按住 ctrl 和鼠标滚轮能触发缩放。'alt'：表示按住 alt 和鼠标滚轮能触发缩放。。
      moveOnMouseMove: true,
      moveOnMouseWheel: true, //鼠标滚轮实现移动
    },
  ],
  series: [
    {
      name: '计划时间段',
      type: 'bar',
      stack: '总量0',
      itemStyle: {
        normal: {
          color: '#ff9f68',
          borderColor: '#fff',
          borderWidth: 2,
        },
      },
      zlevel: -1,
      data: [new Date('2020-05-01'), new Date('2020-03-14'), new Date('2020-05-01')],
      markLine: {
        silent: true,
        data: [
          {
            xAxis: '2022-03-16',
          },
        ],
        lineStyle: {
          normal: {
            type: 'solid',
            color: '#387adf',
          },
        },
      },
    },
    {
      name: '计划时间段',
      type: 'bar',
      stack: '总量0',
      itemStyle: {
        normal: {
          color: '#fff',
        },
      },
      zlevel: -1,
      z: 3,
      data: [new Date('2020-01-01'), new Date('2020-01-01'), new Date('2020-03-15')],
    },
    {
      name: '实际时间段',
      type: 'bar',
      stack: '总量1',
      itemStyle: {
        normal: {
          color: '#17b978',
          borderColor: '#fff',
          borderWidth: 2,
        },
      },
      zlevel: -1,
      data: [new Date('2020-01-10'), new Date('2020-01-10'), new Date('2020-03-30')],
    },
    {
      name: '实际时间段',
      type: 'bar',
      stack: '总量1',
      itemStyle: {
        normal: {
          color: '#fff',
        },
      },
      zlevel: -1,
      z: 3,
      data: [new Date('2020-01-02'), new Date('2020-01-02'), new Date('2020-03-16')],
    },
  ],
}
