import React from 'react';
import './index.scss';
import IOTCard from '@/components/iotCard';
import { EnvironmentApi, Equipment } from '@/api/request';
import { searchParams } from '@/utils';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { Layout, LineLayout, EasyGridLayout } from '@/layout'
import { Card } from '@/components/card';
import { Border1 } from '@/components/border';
import _ from 'lodash';


const lineCode = searchParams().lineCode;

let content = <Loading />;

const handleCardData = (data: any, key: string) => {
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null
    if (!data[0][key] && data[0][key] !== 0) return null
    return Math.floor(data[0][key])
}

function IOT() {

    //获取页面数据
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            EnvironmentApi.getCurrentEnvironmentValues(lineCode),
            Equipment.getCurrentElectricityPowerInW(lineCode),
            Equipment.getTodayElectricityQuantityInKWH(lineCode),
            Equipment.getTotalElectricityQuantityInKWH(lineCode),
            Equipment.getLineCurrentElectricities(lineCode),
            Equipment.getGlueBucketCurrentParameters(lineCode),
            Equipment.getOvensCurrentParameters(lineCode),
        ])
    }, { pollingInterval: 1000 })

    if (loading) {
        return content;
    }

    let currentEnvironmentValuesData, powerConsumption, electricCurrent, voltage, rubberTankPressure, liquidLevelOfGlueTank, equipmentTemperature;

    if (error) {
        // 测试
        equipmentTemperature = [
            { 'name': '烘箱1', 'value': undefined },
            { 'name': '烘箱2', 'value': undefined },
            { 'name': '烘箱3', 'value': undefined },
            { 'name': '热定型', 'value': undefined },
            { 'name': '烘箱4', 'value': undefined },
            { 'name': '烘箱5', 'value': undefined },
            { 'name': '烘箱6', 'value': undefined },
            { 'name': '冷定型', 'value': undefined },
        ];
        liquidLevelOfGlueTank = [
            { 'name': '面处理剂1', 'value': undefined },
            { 'name': '面处理剂2', 'value': undefined },
            { 'name': '面胶1', 'value': undefined },
            { 'name': '面胶2', 'value': undefined },
            { 'name': '底处理剂', 'value': undefined },
            { 'name': '底胶', 'value': undefined },
        ];
        rubberTankPressure = [
            { 'name': '面处理剂1', 'value': undefined },
            { 'name': '面处理剂2', 'value': undefined },
            { 'name': '面胶1', 'value': undefined },
            { 'name': '面胶2', 'value': undefined },
            { 'name': '底处理剂', 'value': undefined },
            { 'name': '底胶', 'value': undefined },
        ];
        voltage = [
            { 'name': 'A相', 'value': undefined },
            { 'name': 'B相', 'value': undefined },
            { 'name': 'C相', 'value': undefined },
        ];
        electricCurrent = [
            { 'name': 'A相', 'value': undefined },
            { 'name': 'B相', 'value': undefined },
            { 'name': 'C相', 'value': undefined },
        ];
        powerConsumption = [
            { 'name': '当前功率', 'value': undefined, 'unit': 'kW' },
            { 'name': '今日能耗', 'value': undefined, 'unit': '度' },
            { 'name': '累积能耗', 'value': undefined, 'unit': '度' },
        ];
        currentEnvironmentValuesData = [
            { 'name': '温度', 'value': undefined, 'unit': '℃' },
            { 'name': '湿度', 'value': undefined, 'unit': '%' },
            { 'name': '噪音', 'value': undefined, 'unit': 'dB' },
            { 'name': 'PM2.5', 'value': undefined, 'unit': 'ug/m³' }
        ]
        // 测试
    } else {
        let [currentEnvironmentValues, currentElectricityPowerInW, todayElectricityQuantityInKWH, totalElectricityQuantityInKWH, lineCurrentElectricities, glueBucketCurrentParameters, ovensCurrentParameters]: any = data;

        currentEnvironmentValuesData = [
            { 'name': '温度', 'value': !currentEnvironmentValues.temperatureInC ? currentEnvironmentValues.temperatureInC : Math.floor(currentEnvironmentValues.temperatureInC * 10) / 10, 'unit': '℃' },
            { 'name': '湿度', 'value': !currentEnvironmentValues.humidness ? currentEnvironmentValues.humidness : Math.floor(currentEnvironmentValues.humidness * 100), 'unit': '%' },
            { 'name': '噪音', 'value': !currentEnvironmentValues.noiseIndB ? currentEnvironmentValues.noiseIndB : Math.floor(currentEnvironmentValues.noiseIndB * 10) / 10, 'unit': 'dB' },
            { 'name': 'PM2.5', 'value': !currentEnvironmentValues.pM25 ? currentEnvironmentValues.pM25 : Math.floor(currentEnvironmentValues.pM25 * 10) / 10, 'unit': 'ug/m³' }
        ]

        powerConsumption = [
            { 'name': '当前功率', 'value': !currentElectricityPowerInW ? currentElectricityPowerInW : Math.floor(currentElectricityPowerInW / 1000 * 100) / 100, 'unit': 'kW' },
            { 'name': '今日能耗', 'value': !todayElectricityQuantityInKWH ? todayElectricityQuantityInKWH : Math.floor(todayElectricityQuantityInKWH), 'unit': '度' },
            {
                'name': '累积能耗',
                'value': !totalElectricityQuantityInKWH ? totalElectricityQuantityInKWH :
                    Math.floor(totalElectricityQuantityInKWH) > 9999 ? _.round(totalElectricityQuantityInKWH / 10000, 1) :
                        Math.floor(totalElectricityQuantityInKWH) > 999 ? Math.floor(Math.floor(totalElectricityQuantityInKWH) / 1000) + ',' + (Math.floor(totalElectricityQuantityInKWH).toString().slice(-3)) :
                            Math.floor(totalElectricityQuantityInKWH),
                'unit': Math.floor(totalElectricityQuantityInKWH) > 9999 ? '万度' : '度'
            },
        ]

        electricCurrent = [
            { 'name': 'A相', 'value': handleCardData(lineCurrentElectricities, 'currentAInA') },
            { 'name': 'B相', 'value': handleCardData(lineCurrentElectricities, 'currentBInA') },
            { 'name': 'C相', 'value': handleCardData(lineCurrentElectricities, 'currentCInA') },
        ];

        voltage = [
            { 'name': 'A相', 'value': handleCardData(lineCurrentElectricities, 'voltageAInV') },
            { 'name': 'B相', 'value': handleCardData(lineCurrentElectricities, 'voltageBInV') },
            { 'name': 'C相', 'value': handleCardData(lineCurrentElectricities, 'voltageCInV') },
        ];

        liquidLevelOfGlueTank = [
            { 'name': '面处理剂1', 'value': undefined },
            { 'name': '面处理剂2', 'value': undefined },
            { 'name': '面胶1', 'value': undefined },
            { 'name': '面胶2', 'value': undefined },
            { 'name': '底处理剂', 'value': undefined },
            { 'name': '底胶', 'value': undefined },
        ];
        rubberTankPressure = [
            { 'name': '面处理剂1', 'value': undefined },
            { 'name': '面处理剂2', 'value': undefined },
            { 'name': '面胶1', 'value': undefined },
            { 'name': '面胶2', 'value': undefined },
            { 'name': '底处理剂', 'value': undefined },
            { 'name': '底胶', 'value': undefined },
        ];
        glueBucketCurrentParameters.forEach((item: any, index: number) => {
            liquidLevelOfGlueTank[index].value = !item.positionInMillimeter ? item.positionInMillimeter : Math.floor(item.positionInMillimeter);
            rubberTankPressure[index].value = !item.positionInMillimeter ? item.positionInMillimeter : Math.floor(item.pressureInPa / 1000000 * 100) / 100;
        });

        equipmentTemperature = [
            { 'name': '烘箱1', 'value': undefined },
            { 'name': '烘箱2', 'value': undefined },
            { 'name': '烘箱3', 'value': undefined },
            { 'name': '热定型', 'value': undefined },
            { 'name': '烘箱4', 'value': undefined },
            { 'name': '烘箱5', 'value': undefined },
            { 'name': '烘箱6', 'value': undefined },
            { 'name': '冷定型', 'value': undefined },
        ];
        ovensCurrentParameters.forEach((item: any) => {
            equipmentTemperature[item.ovenCode - 1].value = !item.temperatureInC ? item.temperatureInC : Math.floor(item.temperatureInC * 10) / 10;
        });
    }

    content =
        <Layout name='ZS-TEK D项目智能化物联网看板'>
            <LineLayout margin={[20, 0, 0, 0]} >
                <Card border={<Border1 />} width={400} height={970} title='设备温度(℃)' >
                    <EasyGridLayout rows={4} columns={2} width={400} height={880} margin={[30, 0, 0, 19]}>
                        {equipmentTemperature.map((item: any, index: any) => {
                            return (<IOTCard key={index} data={item} />);
                        })}
                    </EasyGridLayout>
                </Card>
                <LineLayout direction='column'>
                    <Card border={<Border1 />} width={570} height={470} title='胶罐液位(mm)' >
                        <EasyGridLayout rows={2} columns={3} width={550} height={380} margin={[30, 0, 0, 19]}>
                            {liquidLevelOfGlueTank.map((item: any, index: any) => {
                                return (<IOTCard key={index} data={item} />);
                            })}
                        </EasyGridLayout>
                    </Card>
                    <Card border={<Border1 />} width={570} height={470} title='胶罐压力(MPa)' >
                        <EasyGridLayout rows={2} columns={3} width={550} height={380} margin={[30, 0, 0, 19]}>
                            {rubberTankPressure.map((item: any, index: any) => {
                                return (<IOTCard key={index} data={item} />);
                            })}
                        </EasyGridLayout>
                    </Card>
                </LineLayout>
                <LineLayout direction='column'>
                    <Card border={<Border1 />} width={400} height={470} title='电压(V)' >
                        <EasyGridLayout rows={2} columns={2} width={400} height={380} margin={[30, 0, 0, 19]}>
                            {voltage.map((item: any, index: any) => {
                                return (<IOTCard key={index} data={item} />);
                            })}
                        </EasyGridLayout>
                    </Card>
                    <Card border={<Border1 />} width={400} height={470} title='电流(A)' >
                        <EasyGridLayout rows={2} columns={2} width={400} height={380} margin={[30, 0, 0, 19]}>
                            {electricCurrent.map((item: any, index: any) => {
                                return (<IOTCard key={index} data={item} />);
                            })}
                        </EasyGridLayout>
                    </Card>
                </LineLayout>
                <LineLayout direction='column'>
                    <Card border={<Border1 />} width={400} height={470} title='功率能耗' >
                        <EasyGridLayout rows={2} columns={2} width={400} height={380} margin={[30, 0, 0, 19]}>
                            {powerConsumption.map((item: any, index: any) => {
                                return (<IOTCard key={index} data={item} />);
                            })}
                        </EasyGridLayout>
                    </Card>
                    <Card border={<Border1 />} width={400} height={470} title='环境' >
                        <EasyGridLayout rows={2} columns={2} width={400} height={380} margin={[30, 0, 0, 19]}>
                            {currentEnvironmentValuesData.map((item: any, index: any) => {
                                return (<IOTCard key={index} data={item} />);
                            })}
                        </EasyGridLayout>
                    </Card>
                </LineLayout>
            </LineLayout>
        </Layout>

    return content;
}

export { IOT };