export default {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  legend: {
    show: false,
    x: 'right',
    orient: 'vertical',
    y: -5,
    textStyle: {
      color: '#ccc',
      fontSize: '12px',
    }
  },
  toolbox: {
    feature: {
      // saveAsImage: {}
    }
  },
  grid: {
    left: '30',
    right: '20',
    top: '20',
    bottom: '',
    containLabel: true
  },



  //  {
  //         type: 'category',
  //         boundaryGap: false,
  //         data: this.handleMonthData(),
  //         splitLine: {
  //             show: true,
  //             lineStyle: {
  //                 color: '#183751'
  //             }
  //         },
  //         axisLabel: {
  //             color: '#bfbfbf'
  //         },
  //         axisLine: {
  //             lineStyle: {
  //                 color: '#102334',
  //                 opacity: 0.5
  //             }
  //         }
  //     }

  xAxis: [
    {
      splitLine: {
        show: true,
        lineStyle: {
          color: '#183751',
          width: 1,
          type: 'solid'
        }
      },

      type: 'category',

      axisLabel: {
        textStyle: {
          color: '#bfbfbf',
          fontSize: 22
        }
      },
      axisLine: {
        lineStyle: {
          opacity: 0.5,
          color: '#102334',
        }
      }

    }
  ],
  yAxis: [
    {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: '#183751'
        }
      },
      axisLabel: {
        color: '#bfbfbf'
      },
      axisLine: {
        lineStyle: {
          color: '#102334',
          opacity: 0.5
        }
      }
    },
  ],
  series: [

    {
      markPoint: {
        label: {
          fontSize: 20,
          color: '#fff'
        },
        symbol: 'pin', //标记(气泡)的图形
        symbolSize: 0, //标记(气泡)的大小
        itemStyle: {
          color: '#05d380', //图形的颜色。
          borderColor: '#Fff',//图形的描边颜色。支持的颜色格式同 color，不支持回调函数。
          borderWidth: 0,//描边线宽。为 0 时无描边。
          borderType: 'solid' //柱条的描边类型，默认为实线，支持 ‘solid’, ‘dashed’, ‘dotted’。
        },
        data: [
          { type: 'max', name: '最大值' },

        ]
      },

      smooth: true,
      name: '',
      type: 'line',
      barWidth: '60%',
      markLine: {
        silent: true,
        symbol: 'none',
        label: {
          show: false
        },
        lineStyle: {
          type: 'dashed',
          color: '#FC9800',
          width: 2
        },
      },
      itemStyle: {

      },

    }
  ]
}