/*
 * @Description:基础趋势图组件
 * @Author: huixu
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import option from './mLineOptions.js'
import './chart.scss'
interface TitleStyle {
    fontSize?: number
    color?: string
    value: string
}
interface AxisStyle {
    fontSize?: number,
    color?: string
}
interface Props {
    id: string
    height?: string | number,
    title?: string | TitleStyle
    margin?: string
    data?: any,
    axisStyle?: AxisStyle,
    legend?: Array<string>,
    grid?: Array<string>,
    titleSize?: number
}
interface State {
    chart: echarts.ECharts
    margin: string
}
export class MultipleLineChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            chart: null,
            margin: props.margin,
        }
    }

    drawChart() {
        if (this.state.chart) {
            this.setChart()
            return false
        }
        this.setState(
            {
                chart: echarts.init(document.getElementById(`line-chart-${this.props.id}`) as HTMLDivElement),
            },
            () => {
                this.setChart()
            }
        )
    }

    setChart() {

        let lineStyle = {
            smooth: true,
            name: '',
            type: 'line',
            barWidth: '60%',
            markLine: {
                silent: true,
                symbol: 'none',
                label: {
                    show: false
                },
                lineStyle: {
                    type: 'dashed',
                    color: '#FC9800',
                    width: 2
                },
            },


        }

        option.legend.data = this.props.legend
        option.xAxis[0].data = this.props.data.xAxis
        let series = []
        if (this.props.legend) {
            series = this.props.data.yAxis.map((item: any, idx: number) => {
                return { ...lineStyle, data: item, name: this.props.legend[idx] }
            })
        }




        if (this.props.grid) {
            option.grid.top = this.props.grid[0]
            option.grid.left = this.props.grid[1]
            option.grid.bottom = this.props.grid[2]
            option.grid.right = this.props.grid[3]
        }

        option.xAxis[0].axisLine.fontSize = 30
        option.series = series
        this.state.chart.setOption(option as any, true)
    }

    componentDidMount() {
        this.drawChart()
    }
    componentDidUpdate() {
        this.drawChart()
    }

    render() {
        return (
            <div style={{ margin: this.state.margin, width: '100%', height: '100%' }}>
                <div className="chart-title" style={{ marginLeft: '20px', fontSize: this.props.titleSize + 'px' }}>{this.props.title}</div>
                <div id={`line-chart-${this.props.id}`} style={{ width: '100%', height: this.props.height, marginTop: '80' }}>
                </div>
                {/* <div className='number'>30</div> */}

            </div>
        )
    }
}
