/*
 * @Description:质量缩略图
 * @Author: huixu
 */
import React from 'react'
import { LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { PieChart } from '@/components/chart/pieChart'
import * as API from '@/api/request'
import { useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import _ from 'lodash';

const lineCode = searchParams().lineCode || null

let content = <Loading />
interface Props {
  data?: Object | Array<any>
}

/**
 * [
  {
    "defectId": 0,
    "defectCode": "string",
    "defectName": "string",
    "count": 0
  }
]
 */
const handleChartData = (data: any) => {
  if (!Array.isArray(data)) {
    return []
  }
  if (data.length === 0) {
    return []
  }
  // console.log(data)
  return data.map((item: any) => {
    return { value: item.count, name: item.defectName }
  })
}

//测试数据
// const testData = [
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '涂胶断胶水',
//     count: 20,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '涂胶端胶',
//     count: 30,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '坏掉了',
//     count: 0,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '溢胶',
//     count: 34,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '涂处理',
//     count: 40,
//   },
// ]
const Quality = (props: Props) => {
  // 质量
  let { data, loading, error } = useRequest(
    () => {
      return Promise.all([
        API.Quality.getTodayFirstPassYield(lineCode),
        API.Quality.getTodayNotGoodCount(lineCode),
        API.Quality.getTodayNgCountByDefectTypes(lineCode),
      ])
    },
    { pollingInterval: 3000 }
  )
  if (loading) return content
  if (error) {
    content = <Error />
    return content
  }
  content = <React.Fragment>
    <LineLayout justify="start" padding={[20, 20, 0, 30]}>
      {/* 临时修改，后续需要整理 */}
      <DigitalFlop count={_.floor(data[0] * 100, 1)} suffix="%" title="直通率" minWidth={180} type='count' />
      <DigitalFlop count={data[1]} suffix="次" title="质量异常" type='count' />
    </LineLayout>
    <PieChart id="quality-thumbnail" unit='次' margin="30px 0 0 0" data={handleChartData(data[2])} color={['#fac858', '#ef6667', '#546fc6', '#91cd76', '#fac958']} title="质量异常分析" />
  </React.Fragment>
  return content
}
export { Quality }
