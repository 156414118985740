import { createStore } from 'redux';
const defaultState = {
    projectInfo: {
        name: '',
        shortCode: '',
        id: ''
    },
    hasCallButtonSystem: false,
    installedLineCodes: [],
    robots: [],
    cameras: [],
    ovenOrRefrigerators: [],
    glueBuckets: [],
    hasStopperWorkStations: []
}

const reducer = (state: any, action: any) => {
    const { value, type } = action
    switch (type) {
        case 'set_project_info':
            defaultState.projectInfo = value
            break;
        case 'set_has_call_system':
            defaultState.hasCallButtonSystem = value
            break;
        case 'set_install_linecodes':
            defaultState.installedLineCodes = value
            break;
        case 'set_robots':
            defaultState.robots = value
            break;
        case 'set_cameras':
            defaultState.cameras = value
            break;
        case 'set_oven_refrigerator':
            defaultState.ovenOrRefrigerators = value
            break;
        case 'set_glue_bucket':
            defaultState.glueBuckets = value
            break;
        case 'set_has_stopper-work-station':
            defaultState.hasStopperWorkStations = value
            break;
        default:
            break
    }
    return defaultState
}

const store = createStore(reducer);

const setProjectInfo = (value: any) => {
    store.dispatch({
        type: 'set_project_info',
        value: value
    })
}

const setHasCallButtonSystem = (value: any) => {
    store.dispatch({
        type: 'set_has_call_system',
        value: value
    })
}

const setInstallLineCodes = (value: any) => {
    store.dispatch({
        type: 'set_install_linecodes',
        value: value
    })
}

const setRobots = (value: any) => {
    store.dispatch({
        type: 'set_robots',
        value: value
    })

}


const setCameras = (value: any) => {
    store.dispatch({
        type: 'set_cameras',
        value: value
    })
}

const setOvenOrRefrigerators = (value: any) => {
    store.dispatch({
        type: 'set_oven_refrigerator',
        value: value
    })
}

const setGlueBuckets = (value: any) => {
    store.dispatch({
        type: 'set_glue_bucket',
        value: value
    })
}

const setHasStopperWorkStations = (value: any) => {
    store.dispatch({
        type: 'set_has_stopper-work-station',
        value: value
    })
}

export { store, setRobots, setProjectInfo, setHasCallButtonSystem, setInstallLineCodes, setCameras, setOvenOrRefrigerators, setGlueBuckets, setHasStopperWorkStations }

