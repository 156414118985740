import service from '../service'
import moment from 'moment'
interface RequestConfig {
    params?: Object
    data?: Object
}
/**
 * 获取今日的呼叫次数
 */

const today = { beginTime: moment().format('YYYY-MM-DD'), endTime: moment().add(1, 'day').format('YYYY-MM-DD') }

export default {
    getTodayCallCount: (lineCode?: string) => {
        return service({
            url: '/Call/GetCountByTimeRangeByCallType',
            params: { lineCode: lineCode, ...today, timeRangeType: 0 }
        })
    },



    /**
     * 根据时间和产线返回所有呼叫类型呼叫次数
     * @param lineCode 
     * @returns 
     */
    getCountByTimeRangeByCallType: (lineCode?: string | null, timeRange?: { beginTime: string, endTime: string } | {}) => {
        return service({
            url: '/Call/GetCountByTimeRangeByCallType',
            params: { lineCode, ...timeRange, timeRangeType: 0 }
        })
    },

    /**
     * 获取正在呼叫的呼叫记录
     * @param lineCode 产线
     * @returns 
     */
    getCallingRecords: (lineCode?: string, callType?: number) => {
        return service({
            url: '/Call/GetCallingRecords',
            params: { lineCode: lineCode, orderByBeginTimeDescending: false }
        })
    },

    /**
     * 获取是否安装了呼叫系统
     */

    hasCallButtonSystem: (lineCode?: string) => {
        return service({
            url: '/Call/HasCallButtonSystem',
            params: { lineCode: lineCode }
        })
    }

    // /Call/HasCallButtonSystem



}





