import service from '../service'
import { timeRanges } from '@/utils/time'

export default {

    /**
     * 获取最新的工位托盘数量信息
     * @param lineCode
     */
    getWorkstationCurrentPalletCounts: (lineCode?: string) => {
        return service({
            url: '/Logistics/GetWorkstationCurrentPalletCounts',
            params: { lineCode }
        })
    },

    /**
     * 获取最新的工位托盘数量信息, 只需要今天的
     * @param lineCode
     * @param workStationCode
     */
    getStatisticValuesByWorkStation: (lineCode?: string, workStationCode?: string) => {
        return service({
            url: '/Logistics/GetStatisticValuesByWorkStation',
            params: { ...timeRanges.today(), lineCode, workStationCode }
        })
    },
}