import React, { useState } from 'react';
import { EasyGridLayout, LineLayout } from '@/layout';
import { PieChart } from '@/components/chart/pieChart';
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop';
import { GanttChart } from '@/components/chart/ganttChart';
import './index.scss';
import { useRequest } from 'ahooks';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { start } from 'nprogress';

const service = axios.create({
    baseURL: 'http://api.mis.zs-tek.com',
    withCredentials: true,
    timeout: 60 * 1000 * 3,
})

const handleGanttChart = (data) => {
    let result = { taskContent: [], plannedBeginTime: [], plannedEndTime: [], actualBeginTime: [], actualEndTime: [], };
    data.forEach((element: any) => {
        result.taskContent.push(element.TaskContent);
        result.plannedBeginTime.push(element.ProgramStartDate);
        result.plannedEndTime.push(element.ProgramEndDate);
        result.actualBeginTime.push(element.ActualStartDate);
        result.actualEndTime.push(element.ActualEndDate === null && element.ActualStartDate !== null ? moment().format('YYYY-MM-DD') : element.ActualEndDate);
    });
    return result;
}

const handleDataSummary = (data: Array<any>) => {
    let summary = {
        all: null,
        completed: null,
        inCompleted: null,
    };
    summary.all = data.length;
    data.forEach((element: any) => {
        if (element.IsItDone === '是') {
            summary.completed++;
        } else {
            summary.inCompleted++;
        }
    });
    return summary;
}

const ConstructionPlan = () => {
    let content = <Loading />
    const { data, error, loading } = useRequest(() => {
        return Promise.all([service({ url: '/api/InstitutionalManagement', params: { InternalCode: 'jdd3' } })])
    })
    let ganttData = [];
    let returnData = [];
    let summaryData = {
        all: null,
        completed: null,
        inCompleted: null,
    };
    if (data !== undefined) {
        returnData = data[0].data;
        summaryData = handleDataSummary(returnData);
        // console.log(returnData);
        returnData.forEach((item: any) => {
            if (item.RemainingDays >= 0) {
                ganttData.push(item);
            }
        })
        ganttData.sort((a, b) => { return a.RemainingDays - b.RemainingDays })// 升序
        ganttData = ganttData.slice(0, 5);
        // console.log(ganttData);
    }

    if (loading) return content
    if (error) {
        content = <Error />
        return content
    }

    return <React.Fragment>
        <LineLayout justify="start" padding={[20, 20, 0, 30]} direction='row'>
            <div style={{ width: '75%', marginTop: '10px' }}>
                <GanttChart id="constructionPlanGanttChart" data={handleGanttChart(ganttData)} height="300px" markLine={moment().format('YYYY-MM-DD')} dataZoom={{ show: false, start: 0, end: 100 }} />
            </div>
            <div style={{ width: '5%' }}></div>
            <div className='constructionPlan-digital'>
                <DigitalFlop count={summaryData.all ?? null} suffix="个" title="合计" minWidth={180} type='count' />
                <DigitalFlop count={summaryData.all > 0 ? _.floor(summaryData.completed / summaryData.all * 100, 0) : summaryData.all} suffix="%" title="完成率" type='count' />
            </div>
            <div className='constructionPlan-digital'>
                <DigitalFlop count={summaryData.completed ?? null} suffix="个" title="已完成" minWidth={180} type='count' />
                <DigitalFlop count={summaryData.inCompleted ?? null} suffix="个" title="未完成" color="#efad1f" type='count' />
            </div>
            <div className='constructionPlan-pieChart'>
                <PieChart id="constructionPlan-thumbnail" margin="40px 0 0 0" title="完成情况" color={['#05d380', '#eee']} unit='%' data={[
                    { name: '已完成', value: summaryData.all > 0 ? _.floor(summaryData.completed / summaryData.all * 100, 0) : summaryData.all },
                    { name: '未完成', value: summaryData.all > 0 ? 100 - _.floor(summaryData.completed / summaryData.all * 100, 0) : 100 - summaryData.all },
                ]} />
            </div>
        </LineLayout>
    </React.Fragment >

}

export { ConstructionPlan }