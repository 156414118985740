import React from 'react'
import { Route } from 'react-router-dom'
interface PrivateRouteProps {
    path: string,
    content: any
}
export const PrivateRoute = (props: PrivateRouteProps) => {
    window.scrollTo(0, 0)
    //TODO:路由拦截
    //@ts-ignore
    return <Route exact path={props.path} >
        {props.content}
    </Route>

}