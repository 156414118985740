/*
 * @Description:布局组件
 * @Author: huixu
 */
import React, { Children } from 'react'
import Header from '../components/header'
import classnames from 'classnames'
import FreedomLayout from './gLayout'
import './index.scss'

interface layoutProps {
  name?: string
  children?: Object | Array<Object> | null
}
interface LineLayoutProps {
  padding?: number | Array<number>
  margin?: number | Array<number>
  width?: number | string
  height?: number | string
  background?: string
  children?: Object | Array<any>
  justify?: 'between' | 'around' | 'end' | 'start'
  direction?: 'row' | 'column'
  gap?: number
  wrap?: boolean,
  radius?: number
}

interface SplitLine {
  show: Boolean
  color: string
}
interface EasyGridLayoutProps {
  background?: string
  width?: number
  height?: number
  rows: number
  columns: number
  padding?: Array<number> | number
  margin?: Array<number> | number
  itemPadding?: Array<number> | number
  splitLintColor?: string
  borderColor?: string
  children?: Object | Array<Object>
  center?: Boolean
  splitLine?: Boolean
  gap?: number
}

interface CenterLayoutProps {
  width?: number
  height?: number
  border?: Object
  children?: Object | Array<Object>
  layoutKey?: string
}
/**
 * 转换margin或者padding 返回拼接好的字符串，直接在 style里面使用
 * @param style
 * @returns
 */
const transStyle = (style: Array<number> | number): string => {
  let styleStr: string = ''
  if (Array.isArray(style)) {
    style.forEach((item) => {
      styleStr = styleStr + ' ' + item + 'px'
    })
    return styleStr
  }
  return style + 'px'
}

/**
 * 基本布局
 * @param props
 * @returns
 */
const Layout = (props: layoutProps) => {

  return (
    <div className="base-layout">
      <Header name={props.name} />
      <div className="base-layout-content">{props.children}</div>
    </div>
  )
}

/**
 * 线性布局
 * @param props
 * @returns
 */

const LineLayout = (props: LineLayoutProps) => {
  const justifyContentMap = {
    between: 'space-between',
    around: 'space-arround',
    end: 'flex-end',
    start: 'flex-start',
  }

  return (
    <div
      className={classnames({
        'line-layout': true,
        vertica: true,
        horizontal: false,
      })}
      style={{
        gap: props.gap + 'px',
        flexDirection: props.direction,
        margin: transStyle(props.margin),
        padding: transStyle(props.padding),
        width: props.width + 'px',
        height: props.height + 'px',
        background: props.background,
        justifyContent: justifyContentMap[props.justify],
        borderRadius: props.radius + 'px',
        flexWrap:props.wrap?"wrap":"nowrap"

      }}
    >
      {props.children}
    </div>
  )
}

/**
 * 一种简单的网格布局 子元素会自动排列
 * 子元素的大小一致
 * 获得容器的宽高之后需要计算出子元素的宽高 动态设置grid-template-columns 和 grid-template-rows这两个属性的值
 * 计算子元素宽度 width/coulums;计算子元素高度  height/rows
 * TODO:网格线的颜色
 * @param props
 * @returns
 */
const EasyGridLayout = (props: EasyGridLayoutProps) => {
  /**
   * 判断元素是否在网格的最后一行或者最后一列 实现网格中间的分割线
   * @param itemIdx
   * @param allRows
   * @param allCoulums
   * @returns
   */

  const isGridItemtLast = (itemIdx: number, allRows: number, allCoulums: number): any => {
    return {
      row: Math.ceil((itemIdx + 1) / allRows) === allRows,
      coulum: (itemIdx + 1) % allRows === 0,
    }
  }

  //加入 gap 计算 grid行高列宽
  let gap = props.gap ? props.gap : 0
  const rowWidth = () => (props.width - gap * (props.columns - 1)) / props.columns
  const columnHeight = () => (props.height - gap * (props.rows - 1)) / props.rows

  let easyGridLayoutStyles = {
    alignItems: 'center',
    display: 'grid',
    width: props.width,
    height: props.height,
    gridTemplateColumns: `repeat(${props.columns}, ${rowWidth()}px)`,
    gridTemplateRows: `repeat(${props.rows}, ${columnHeight()}px)`,
    padding: transStyle(props.padding),
    margin: transStyle(props.margin),
    background: props.background,
    gap: props.gap + 'px',
  }
  let easyGridLayoutItemsStyle = {
    padding: transStyle(props.itemPadding),
  }
  return (
    <div className="easy-grid-layout" style={easyGridLayoutStyles}>
      {Children.map(props.children, (item: any, idx: number) => {
        return (
          <div
            style={easyGridLayoutItemsStyle}
            className={classnames({
              'item-bg': true,
              'item-border-bottom': !isGridItemtLast(idx, props.rows, props.columns).row && props.splitLine,
              'item-border-right': !isGridItemtLast(idx, props.rows, props.columns).coulum && props.splitLine,
            })}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

/**
 * 可以让子元素水平垂直居中的布局容器
 * @param props
 */
const CenterLayout = (props: CenterLayoutProps) => {
  return (
    <div className="center-layout" style={{ height: props.height + 'px', width: props.width + 'px' }}>
      <div className='center-layout-content'>
        {props.children}

      </div>
      {props.border}

    </div>
  )
}

export { Layout, LineLayout, EasyGridLayout, CenterLayout, FreedomLayout }
