import React from 'react';
import { LineLayout } from '@/layout';
import './index.scss';
import { Image, Empty } from 'antd';


interface Props {
    title?: string,
    imageUrl?: any,
}

const OrgPhoto = (props: Props) => {

    return <React.Fragment>
        <LineLayout justify="start" padding={[20, 20, 20, 17]} direction='column'>
            <div className='orgPhoto-tilte'>{props.title}</div>
            {props.imageUrl ? <Image
                width={395}
                height={320}
                src={props.imageUrl}
            /> : <Empty
                description={<span className='orgPhoto-empty'>
                    暂无数据
                </span>}
                imageStyle={{height: '230px'}}
                />}
        </LineLayout>
    </React.Fragment>
}

export { OrgPhoto }