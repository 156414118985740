import React from 'react';
import { VideoPlayer } from '@/components/videoPlayer';
import { LineLayout } from '@/layout';
import './index.scss';
import axios from 'axios';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';

const service = axios.create({
    baseURL: 'https://open.ys7.com',
    method: 'post',
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: true,
})

const OrgLive = () => {
    let content = <Loading />
    const { data, error, loading } = useRequest(() => {
        return Promise.all([service({ url: '/api/lapp/token/get', params: { appKey: '305ce83133e3474ea4031d0589f4ae54', appSecret: 'b4713e0627f19beb6db74fecd0b33940' }, })])
    })
    let token = '';

    if (data) {
        token = data[0].data.data.accessToken;
    }
    if (loading) return content
    if (error) {
        content = <Error />
        return content
    }

    return <React.Fragment>
        <LineLayout justify="start" padding={[20, 20, 20, 17]} direction='row'>
            <div className='orgLive-tilte'>线尾</div>
        </LineLayout>
        {/* 视频直播和机器人状态 */}
        <VideoPlayer
            width={395}
            height={320}
            // ezopen://验证码@open.ys7.com
            url={'ezopen://open.ys7.com/J22670270/1.hd.live'}
            token={token}
            margin='0 0 0 17px'
        />
    </React.Fragment>
}

export { OrgLive }