import React, { Children, useEffect, useLayoutEffect, useState } from 'react'
import { Layout, LineLayout, CenterLayout } from '@/layout'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { Border1 } from '@/components/border'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { Card } from '@/components/card'
import { LineChart } from '@/components/chart/lineChart'
import { useMount, useRequest, useUpdateLayoutEffect } from 'ahooks'
import * as API from '@/api/request'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { searchParams, handleDataForLineChart } from '@/utils'
import { formatLineChartXAxis, getTimeRangeByUrl, timeRanges } from '@/utils/time'
import _ from 'lodash'
import FormatterType from '@/components/chart/formatterType';
const handleBigCount = (count: number, suffix: string) => {
  if (count > 10000) return { count: _.round(count / 10000, 1), suffix: '万' + suffix }
  return { count: _.round(count), suffix: suffix }
}

const Equipment = () => {
  const lineCode = useState(searchParams().lineCode)[0]
  const toolbarIsVisiable = useState(searchParams().toolbarIsVisiable)[0]
  const pageName = useState((toolbarIsVisiable === 'false') ? '能源' : 'ZS-TEK智能化产线-能源')[0]
  const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
  const [titlePrefix, setTitlePrefix] = useState('今日')
  //获取页面数据
  let { data, loading, error } = useRequest(() => {
    return Promise.all([
      API.Equipment.getCurrentElectricityPowerInW(lineCode),
      API.Equipment.getlectricityValues(lineCode, timeRange),
      API.Equipment.getUtilizationHours(lineCode, timeRange),
      API.Equipment.getUtilizationRateByTimeRange(lineCode, timeRange),
      API.Equipment.getElectricityQuantityInKWHByTimeRange(lineCode, timeRange)
    ])
  }, { refreshDeps: [timeRange] })
  const titlePrefixMap = {
    0: '今日',
    1: '本周',
    2: '本月',
    3: '今年',
    4: '今年',
    5: '今年',
    6: '累积'
  }
  const changeTimePeriodSelector = (timeRange: any) => {
    setTimeRange(timeRange)
    setTitlePrefix(titlePrefixMap[timeRange.timeRangeType])
  }
  const getElement = (children?: JSX.Element) => {
    return <Layout name={pageName}>
      <TimePeriodSelector change={(timeRange: any) => { changeTimePeriodSelector(timeRange) }} />
      {children}
    </Layout>
  }
  if (loading) {
    return getElement(<Loading />)
  }
  if (error) {
    return getElement(<Error />)
  }
  let [powerInW, elctricityValues, utilizationHours, utilizationRate, electricityQuantity] = data
  let numCardsLeft = [
    { count: _.round(utilizationHours.utilizationRate * 100, 1), suffix: "% ", title: `${titlePrefix}稼动率`, type: 'count' },
    { count: _.round(utilizationHours.usedHours, 1), suffix: "小时", title: `${titlePrefix}开机时长`, type: 'time' },
    { count: _.round(utilizationHours.waitingHours), suffix: "小时", title: `${titlePrefix}待机时长`, type: 'time', color: 'orange' }
  ]
  let numCardsRight = [
    { ...handleBigCount(elctricityValues.quantityInKWH, '度'), title: `${titlePrefix}能耗`, type: 'count', color: 'orange' },
    { count: _.round(elctricityValues.energyUtilizationInPairPerKWH, 1), suffix: "双/度", title: `${titlePrefix}能源利用率`, type: 'count' },
    { count: _.round(powerInW / 1000, 1), suffix: "kW", title: '当前功率', type: 'count' }
  ]

  const handleUtilizationRate = (timeRangeType: number, data: Array<any>) => {
    let tem = data || ['']
    return tem.map(item => {
      if (item.count !== null) {
        return _.round(item.count * 100, 1)
      }
    })

  }


  return getElement(
    <LineLayout>
      <LineLayout justify="start">
        {/* 左边一些数据 */}
        <LineLayout direction="column" width={350} height={870} margin={[0, 30, 0, 30]} gap={50}>
          {
            numCardsLeft.map((item: any) => {
              return <CenterLayout border={<Border1 />}>
                <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.3} type={item.type} color={item.color} />
              </CenterLayout>
            })
          }
        </LineLayout>
      </LineLayout>
      {/* 中间图表 */}
      <LineLayout direction='column' width='1200' justify='between' gap={50}>
        <LineChart id="e1" title={`${titlePrefix}稼动率(%)`} height='93%' titleSize={25} data={{
          xAxis: formatLineChartXAxis((timeRange as any).timeRangeType, utilizationRate),
          yAxis: handleUtilizationRate((timeRange as any).timeRangeType, utilizationRate)
        }}
          formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}
        />
        <LineChart id="e2" title={`${titlePrefix}能耗(度)`} height='93%' titleSize={25} data={handleDataForLineChart((timeRange as any).timeRangeType, electricityQuantity)} formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} />
      </LineLayout>
      {/* 右边一些数据 */}
      <LineLayout justify="start">
        <LineLayout direction="column" width={350} height={870} margin={[0, 30, 0, 30]} gap={50}>
          {
            numCardsRight.map((item: any) => {
              return <CenterLayout border={<Border1 />}>
                <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.3} type={item.type} color={item.color} />
              </CenterLayout>
            })
          }
        </LineLayout>
      </LineLayout>
    </LineLayout>
  )
}
export { Equipment }
