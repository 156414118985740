/*
 * @Description:基础趋势图组件
 * @Author: huixu
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import './chart.scss'

interface AxisStyle {
    fontSize?: number,
    color?: string
}
interface Props {
    id: string
    height?: string | number,
    title?: string
    margin?: string
    data?: any,
    titleSize?: number,
    axisStyle?: AxisStyle
}
interface State {
    chart: echarts.ECharts
    margin: string
}
export class Treemap extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            chart: null,
            margin: props.margin,
        }
    }

    drawChart() {
        if (this.state.chart) {
            this.setChart()
            return false
        }
        this.setState(
            {
                chart: echarts.init(document.getElementById(`treemap-${this.props.id}`) as HTMLDivElement),
            },
            () => {
                this.setChart()
            }
        )
    }

    setChart() {
        let option = {
            grid: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            series: [{
                type: 'treemap',
                data: this.props.data
            }]
        };


        this.state.chart.setOption(option as any, true)
    }

    componentDidMount() {
        this.drawChart()
    }
    componentDidUpdate() {
        this.drawChart()
    }

    render() {
        return (
            <div style={{ margin: this.state.margin, width: '100%', height: '100%' }}>

                <div id={`treemap-${this.props.id}`} style={{ width: '100%', height: '800px' }}>
                </div>
                {/* <div className='number'>30</div> */}

            </div>
        )
    }
}
