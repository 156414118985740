
import React, { Component } from 'react'
import * as echarts from 'echarts'
import './chart.scss'

interface AxisStyle {
    fontSize?: number,
    color?: string
}
interface Props {
    id: string
    height?: string | number,
    title?: string
    margin?: string
    data?: any,
    titleSize?: number,
    axisStyle?: AxisStyle
}
interface State {
    chart: echarts.ECharts
    margin: string
}
export class HorizontalBarChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            chart: null,
            margin: props.margin,
        }
    }
    drawChart() {
        if (this.state.chart) {
            this.setChart()
            return false
        }
        this.setState(
            {
                chart: echarts.init(document.getElementById(`hbar-chart-${this.props.id}`) as HTMLDivElement),
            },
            () => {
                this.setChart()
            }
        )
    }
    setChart() {
        const ovenName = ['烘箱1', '烘箱2', '烘箱3', '热定型', '烘箱4', '烘箱5', '烘箱6', '冷定性']
        let series = this.props.data.map((item: any, idx: number) => {
            return {
                name: ovenName[idx],
                type: 'bar',
                stack: '',
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: item.map((child: any) => child.temperatureInC)
            }
        })
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // Use axis to trigger tooltip
                    type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: {
                x: 80,
                data: ovenName,
                textStyle: {
                    color: '#fff',
                    fontSize: '12px',
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '70px',
                containLabel: true
            },
            xAxis: {
                axisLabel: {
                    textStyle: {
                        color: '#fff',
                        fontSize: 22
                    }
                },
                axisLine: {
                    lineStyle: {
                        show: false,
                        color: '#444c59'
                    }
                },

                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#444c59'],
                        width: 1,
                        type: 'solid'
                    }
                },
                type: 'value',

            },


            yAxis: {

                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#444c59'],
                        width: 1,
                        type: 'solid'
                    }
                },
                axisLabel: { fontSize: 22 },
                offset: 0,
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#fff',
                        width: 0
                    }

                },
                axisTick: {
                    show: false
                },
                type: 'category',
                data: ['产线1', '产线2', '产线3', '产线4', '产线5', '产线6'].reverse()
            },
            series: series
        };

        this.state.chart.setOption(option as any, true)
    }
    componentDidMount() {
        this.drawChart()
    }
    componentDidUpdate() {
        this.drawChart()
    }

    render() {
        return (
            <div style={{ margin: this.state.margin, width: '100%', height: '100%', padding: '30px' }}>
                <div id={`hbar-chart-${this.props.id}`} style={{ width: '100%', height: this.props.height }} />
                {/* <div className='number'>30</div> */}
            </div>
        )
    }
}
