import React, { useState } from 'react'
import { TimePeriodSelector } from '../../../components/timePeriodSelector/timePeriodSelector'
import { Layout, LineLayout } from '../../../layout'
import { Card } from '../../../components/card'
import { Border1 } from '../../../components/border'
import { LineChart } from '@/components/chart/lineChart'
import { HorizontalBarChart } from '@/components/chart/horizontalBarChart'
import { Equipment } from '@/api/request'
import { useRequest } from 'ahooks'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import testData from '@/components/chart/hBarTestData'
import { handleDataForLineChart, handleDataForMoreLineChart } from '@/utils'
import { getTimeRangeByUrl } from '@/utils/time'
import FormatterType from '@/components/chart/formatterType';
const allOveCode = ['1', '2', '3', '4', '5', '6', '7', '8']
const pageName = 'ZS-TEK车间烘箱数据汇总'
export const Oven = () => {
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            Equipment.getOvensCurrentParameters(),
            Equipment.getOvenElectricityQuantityInKWHByTimeRange(null, timeRange),
            Equipment.getOvenElectricityQuantityInKWHByTimeRangeByLineCode(null, timeRange)
        ])
    }, { refreshDeps: [timeRange] })
    if (loading) return <Loading />
    if (error) return <Error />

    const ovensTemperature = allOveCode.map((item: any) => {
        return data[0].filter((child: any) => child.ovenCode === item)
    })


    return <Layout name={pageName} >
        <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }}></TimePeriodSelector>
        <LineLayout width={1920} height={870} margin={[0, 0, 0, 30]} justify='start'>
            <Card border={<Border1 />} width={600} title='烘箱温度' >
                <HorizontalBarChart id='o3' data={ovensTemperature} height={730} />
            </Card>
            <LineLayout direction='column' width={1250} gap={30}>
                <LineChart id='oven2' data={handleDataForMoreLineChart((timeRange as any).timeRangeType, data[2])} title='各产线烘箱能耗' height={400} />
                <LineChart id='oven1' data={handleDataForLineChart((timeRange as any).timeRangeType, data[1])} title='全部能耗' height={400} formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} />
            </LineLayout>
        </LineLayout >
    </Layout>
}
