import React from 'react'
import './index.scss'

const Border1 = () => {
  return <div className="card-border-1" />
}
const Border2 = () => {
  return <div className="card-border-2"></div>
}
const Border3 = () => {
  return <div className="card-border-3"></div>
}
export { Border1, Border2, Border3 }
