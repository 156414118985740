import React, { Component } from 'react'
import './icon.scss'
interface Props {
  size?: number,
  url?: string,
  rotate?: number
}
interface State {
  width: string,
  height: string,
  rotate: number

}
class Icon extends Component<Props, State>{
  constructor(props: Props) {
    super(props)
    this.state = {
      rotate: props.rotate || 0,
      width: props.size + 'rem' || '5rem',
      height: props.size + 'rem' || '5rem'
    }
  }
  render() {
    let iconStyle = {
      transform: `rotate(${this.state.rotate}deg)`,
      width: this.state.width,
      height: this.state.height,
      backgroundImage: `url(${this.props.url})`
    }
    return (
      <div
        className="icon-content"
        style={iconStyle}>
      </div>
    )
  }
}
export default Icon
