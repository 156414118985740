import React, { useState, useEffect } from "react"
import './index.scss'
import Header from '@/components/header'
import { CustomCard } from "@/components/card/customCard"
import { Error } from "@/components/error"
import { EnvironmentApi, Equipment, Production } from '@/api/request'
import { Loading, Decoration3, BorderBox9, BorderBox3 } from '@jiaminghi/data-view-react'
import moment from "moment"
import { useRequest } from 'ahooks'
import _ from 'lodash'

function IOTX () {
  const [requestLoadingState, setRequestLoadingState] = useState(true) // 请求进行中状态

  const lineCode = ['1', '2', '3', '4'] // X1-1:1, X1-2:2, X2-1:3, X2-2:4
  const [localTime, setLocalTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'))
  useEffect(() => {
    const timer1 = setInterval(() => {
      setLocalTime(moment().format('YYYY-MM-DD HH:mm:ss'))
    }, 1000)
    // 清除Interval componentWillUnmount
    return () => { clearInterval(timer1) }
  }, [])

  const [basicInfoList, setBasicInfoList] = useState([
    {
      name: 'X1-1',
      top: 20,
      left: 35,
      todayProduction: null,
      cumulativeProduction: null,
      highestCapacity: null
    },
    {
      name: 'X1-2',
      top: 220,
      left: 35,
      todayProduction: null,
      cumulativeProduction: null,
      highestCapacity: null
    },
    {
      name: 'X2-1',
      top: 550,
      left: 35,
      todayProduction: null,
      cumulativeProduction: null,
      highestCapacity: null
    },
    {
      name: 'X2-2',
      top: 750,
      left: 35,
      todayProduction: null,
      cumulativeProduction: null,
      highestCapacity: null
    }
  ])
  const [factoryInfoList, setFactoryInfoList] = useState([
    {
      icon: require("../../../../assets/images/iot/power.svg"),
      name: '当前功率',
      data: null,
      unit: 'kW'
    },
    {
      icon: require("../../../../assets/images/iot/temperature.svg"),
      name: '温度',
      data: null,
      unit: '℃'
    },
    {
      icon: require("../../../../assets/images/iot/humidity.svg"),
      name: '湿度',
      data: null,
      unit: '%'
    },
    {
      icon: require("../../../../assets/images/iot/noises.svg"),
      name: '噪音',
      data: null,
      unit: 'dB'
    },
    {
      icon: require("../../../../assets/images/iot/pm25.svg"),
      name: 'PM2.5',
      data: null,
      unit: 'μg/m³'
    }
  ])
  // state 0关机 1正常 2报警
  const [lineInfoList, setLineInfoList] = useState([
    // X1-1
    {
      lineName: 'X1-1',
      stationName: '涂处理剂1',
      top: 20,
      left: 1758,
      state: 1
    },
    {
      lineName: 'X1-1',
      stationName: '涂处理剂2',
      top: 20,
      left: 1631,
      state: 1
    },
    {
      lineName: 'X1-1',
      stationName: '涂处理剂3',
      top: 20,
      left: 1493,
      state: 1
    },
    {
      lineName: 'X1-1',
      stationName: '喷处理剂',
      top: 20,
      left: 1219,
      state: 1
    },
    {
      lineName: 'X1-1',
      stationName: '涂胶1',
      top: 20,
      left: 807,
      state: 1
    },
    {
      lineName: 'X1-1',
      stationName: '涂胶2',
      top: 20,
      left: 681,
      state: 1
    },
    {
      lineName: 'X1-1',
      stationName: '喷胶',
      top: 20,
      left: 398,
      state: 1
    },
    // X1-2
    {
      lineName: 'X1-2',
      stationName: '涂处理剂1',
      top: 220,
      left: 1758,
      state: 1
    },
    {
      lineName: 'X1-2',
      stationName: '涂处理剂2',
      top: 220,
      left: 1631,
      state: 1
    },
    {
      lineName: 'X1-2',
      stationName: '涂处理剂3',
      top: 220,
      left: 1493,
      state: 1
    },
    {
      lineName: 'X1-2',
      stationName: '喷处理剂',
      top: 220,
      left: 1219,
      state: 1
    },
    {
      lineName: 'X1-2',
      stationName: '涂胶1',
      top: 220,
      left: 807,
      state: 1
    },
    {
      lineName: 'X1-2',
      stationName: '涂胶2',
      top: 220,
      left: 681,
      state: 1
    },
    {
      lineName: 'X1-2',
      stationName: '喷胶',
      top: 220,
      left: 398,
      state: 1
    },
    // X2-1
    {
      lineName: 'X2-1',
      stationName: '涂处理剂1',
      top: 560,
      left: 1758,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂处理剂2',
      top: 560,
      left: 1631,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂处理剂3',
      top: 560,
      left: 1493,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '喷处理剂',
      top: 560,
      left: 1219,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂胶1',
      top: 560,
      left: 807,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂胶2',
      top: 560,
      left: 681,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '喷胶',
      top: 560,
      left: 398,
      state: 1
    },
    // X2-2
    {
      lineName: 'X2-1',
      stationName: '涂处理剂1',
      top: 760,
      left: 1758,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂处理剂2',
      top: 760,
      left: 1631,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂处理剂3',
      top: 760,
      left: 1493,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '喷处理剂',
      top: 760,
      left: 1219,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂胶1',
      top: 760,
      left: 807,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '涂胶2',
      top: 760,
      left: 681,
      state: 1
    },
    {
      lineName: 'X2-1',
      stationName: '喷胶',
      top: 760,
      left: 398,
      state: 1
    },
  ])

  const handleNullData = (val, digits) => {
    if (typeof val === 'number') {
      return _.floor(val, digits).toLocaleString()
    } else {
      return '--'
    }
  }

  // 发起请求
  const { data, loading, error } = useRequest(() => {
    const tem = []
    // 0 环境
    tem.push(EnvironmentApi.getCurrentEnvironmentValues())
    // 1,2,3,4 功率
    for (const ele of lineCode) {
      tem.push(Equipment.getCurrentElectricityPowerInW(ele))
    }
    // 5,6,7,8 今日产量
    for (const ele of lineCode) {
      tem.push(Production.getTodayCount(ele))
    }
    // 9,10,11,12 累计产量
    for (const ele of lineCode) {
      tem.push(Production.getTotalCount(ele))
    }
    // 13,14,15,16 单时最高产能
    for (const ele of lineCode) {
      tem.push(Production.getMaxCapacityPerHourInDay(ele, { beginTime: moment().format('YYYY-MM-DD'), endTime: moment().add(1, 'days').format('YYYY-MM-DD') }))
    }
    return Promise.all(tem)
  }, {
    pollingInterval: 1000 * 20,
    onSuccess: (res, params) => {
      // console.log(res)
      setRequestLoadingState(false)
      // 环境 功率
      setFactoryInfoList([
        {
          icon: require("../../../../assets/images/iot/power.svg"),
          name: '当前功率',
          data: (Number(res[1]) + Number(res[2]) + Number(res[3]) + Number(res[4])) / 1000 / 10, // 多插了一个0
          unit: 'kW'
        },
        {
          icon: require("../../../../assets/images/iot/temperature.svg"),
          name: '温度',
          data: res[0].temperatureInC,
          unit: '℃'
        },
        {
          icon: require("../../../../assets/images/iot/humidity.svg"),
          name: '湿度',
          data: res[0].humidness * 100,
          unit: '%'
        },
        {
          icon: require("../../../../assets/images/iot/noises.svg"),
          name: '噪音',
          data: res[0].noiseIndB,
          unit: 'dB'
        },
        {
          icon: require("../../../../assets/images/iot/pm25.svg"),
          name: 'PM2.5',
          data: res[0].pM25,
          unit: 'μg/m³'
        }
      ])
      // 今日产量 累计产量 单时最高产能
      setBasicInfoList([
        {
          name: 'X1-1',
          top: 20,
          left: 35,
          todayProduction: res[5],
          cumulativeProduction: res[9] / 10000,
          highestCapacity: res[13]?.[0]?.maxCapacityPerHour
        },
        {
          name: 'X1-2',
          top: 220,
          left: 35,
          todayProduction: res[6],
          cumulativeProduction: res[10] / 10000,
          highestCapacity: res[14]?.[0]?.maxCapacityPerHour
        },
        {
          name: 'X2-1',
          top: 550,
          left: 35,
          todayProduction: res[7],
          cumulativeProduction: res[11] / 10000,
          highestCapacity: res[15]?.[0]?.maxCapacityPerHour
        },
        {
          name: 'X2-2',
          top: 750,
          left: 35,
          todayProduction: res[8],
          cumulativeProduction: res[12] / 10000,
          highestCapacity: res[16]?.[0]?.maxCapacityPerHour
        }
      ])
    }
  })


  if (error) {
    return <div className='upgradesecond-iotX-container'>
      {/* 头部 */}
      <div>
        <Header name={`ZS-TEK 东才智能工厂 X1&X2`} />
      </div>
      <Error></Error>
    </div>
  }

  const DOMContent =
    <div className='upgradesecond-iotX-container'>
      {/* 头部 */}
      <div>
        <Header name={`ZS-TEK 东才智能工厂 X1&X2`} />
      </div>
      {/* 内容 */}
      <div className="ui-body-container">
        <div className="ui-body-content">
          <BorderBox9>
            {
              requestLoadingState ?
                <Loading><span style={{ color: '#FFF' }}>数据加载中...</span></Loading> :
                <div className="ui-illustration">
                  <img src={require('../../../../assets/images/XLine.png')} className='ui-illustration-bg' />

                  {/* 产线基本信息卡片 */}
                  {basicInfoList.map((item: any, index: number) => {
                    return (
                      <div key={index} style={{ position: 'absolute', top: `${item.top}px`, left: `${item.left}px` }} className="ui-basicInfoList">
                        <CustomCard width={300} height={170} bgImageUrl={require('@/assets/images/border6.png')}>
                          <h1>{item.name}</h1>
                          <div className="ui-data">

                            <div className="ui-data-name">今日产量</div>
                            <div className="ui-data-content"><span>{handleNullData(item.todayProduction, 0)}</span>双</div>
                          </div>
                          <div className="ui-data">
                            <div className="ui-data-name">累计产量</div>
                            <div className="ui-data-content"><span>{handleNullData(item.cumulativeProduction, 2)}</span>万双</div>
                          </div>
                          <div className="ui-data">
                            <div className="ui-data-name">单时最高产能</div>
                            <div className="ui-data-content"><span>{handleNullData(item.highestCapacity, 0)}</span>双/时</div>
                          </div>
                        </CustomCard>
                      </div>
                    )
                  })}
                  {/* 装饰 */}
                  <div style={{ position: 'absolute', top: `443px`, left: `55px` }}>
                    <div className="ui-time">{localTime}</div>
                    <Decoration3 style={{ width: '250px', height: '30px' }} />
                  </div>
                  {/* 车间基本信息卡片 */}
                  <div style={{ position: 'absolute', top: `428px`, left: `348px` }} >
                    <BorderBox3 color={['rgb(70, 130, 180)', 'rgb(70, 130, 180)']}>
                      <div style={{ width: '1520px', height: '110px' }} className="ui-factoryInfoList">
                        {factoryInfoList.map((item: any, index: number) => {
                          return (
                            <div key={index} className="ui-data">
                              <img className="ui-data-icon" src={item.icon} />
                              <div className="ui-data-name">{item.name}</div>
                              <div className="ui-data-content"><span>{handleNullData(item.data, 0)}</span>{item.unit}</div>
                            </div>
                          )
                        })}
                      </div>
                    </BorderBox3>
                  </div>
                  {/* 设备状态信息点 */}
                  {lineInfoList.map((item: any, index: number) => {
                    return (
                      <div key={index} style={{ position: 'absolute', top: `${item.top}px`, left: `${item.left}px` }} className="ui-lineInfoList">
                        <h2>{item.stationName}</h2>
                        <div style={{ backgroundColor: item.state === 0 ? '#CCC' : item.state === 1 ? 'rgb(0, 200, 0)' : 'rgb(255, 0, 0)' }}></div>
                      </div>
                    )
                  })}
                </div>
            }
          </BorderBox9>
        </div>
      </div>
    </div >
  return DOMContent
}

export { IOTX }