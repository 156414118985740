export default {
    series: [
        {
            label: {
                color: '#fff',
                fontSize: '18px',
                formatter: function (params) {
                    return params.name + '\n' + params.value
                },
            },
            name: '',
            type: 'pie',
            radius: ['48%', '62%'],
        }
    ]
};