import React, { useState } from 'react';
import { Layout, LineLayout } from '../../../layout';
import * as Thumbnail from '@/components/thumbnail';
import { Border1 } from '@/components/border';
import { Card } from '@/components/card';
import { searchParams } from '@/utils';
import moment from 'moment';

/** 拼接参数 获取跳转的url */
const getJumpTo = (address: string): string => `${address}`

let thumbnails: Array<any> = [
    {
        title: '直播实况', jumpTo: getJumpTo(''), children: <Thumbnail.OrgLive />, border: <Border1 />, width: 420, icon: require('../../../assets/images/live.png')
    },
    // imageUrl={require('@/assets/images/test.png')}
    {
        title: '建制日历', jumpTo: getJumpTo(''), children: <Thumbnail.OrgPhoto title={moment().format('YYYY-MM-DD')} />, border: <Border1 />, width: 420, icon: require('../../../assets/images/calendar.png')
    },
    {
        title: '异常行为', jumpTo: getJumpTo(''), children: <Thumbnail.OrgPhoto title={moment().format('YYYY-MM-DD')} />, border: <Border1 />, width: 420, icon: require('../../../assets/images/abnormal.png')
    },
    { title: '环境', jumpTo: getJumpTo(''), children: <Thumbnail.OrgEnvironment />, border: <Border1 />, width: 420, icon: require('../../../assets/images/th.png') },
]

const ConstructionHome = () => {
    return <Layout name={`建制管理(${new Intl.DateTimeFormat("ch", { dateStyle: "short" }).format(Date.now())})`}>
        <LineLayout margin={20} height={480}>
            {thumbnails
                .map((item, index) => (
                    <Card key={index} title={item.title} jumpTo={item.jumpTo} showMoreBtn={true} border={item.border} width={item.width} children={item.children} icon={item.icon} />
                ))}
        </LineLayout>
        <LineLayout height={440} justify='between' margin={[40, 20, 20, 20]}>
            <Card
                title='施工计划'
                jumpTo={getJumpTo('/organic/plan')}
                showMoreBtn={true}
                border={<Border1 />}
                width={1920}
                children={<Thumbnail.ConstructionPlan />}
                icon={require('../../../assets/images/plan.png')} />
        </LineLayout>
    </Layout>
}

export { ConstructionHome };