// 甘特图
import React, { Component } from 'react'
import * as echarts from 'echarts'
import option from './ganttOptions'
import './chart.scss'

interface AxisStyle {
    fontSize?: number,
    color?: string
}
interface Props {
    id: string
    height?: string | number,
    title?: string
    margin?: string
    data?: any,
    titleSize?: number,
    axisStyle?: AxisStyle,
    markLine?: string
    dataZoom?: any
}
interface State {
    chart: echarts.ECharts
    margin: string
}
export class GanttChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            chart: null,
            margin: props.margin,
        }
    }

    drawChart() {
        if (this.state.chart) {
            this.setChart()
            return false
        }
        this.setState(
            {
                chart: echarts.init(document.getElementById(`gantt-chart-${this.props.id}`) as HTMLDivElement),
            },
            () => {
                this.setChart()
            }
        )
    }

    setChart() {
        option.xAxis.min = this.props.data.plannedBeginTime.slice(0).sort()[0];
        option.yAxis.data = this.props.data.taskContent;
        option.series[0].data = this.props.data.plannedEndTime;
        option.series[1].data = this.props.data.plannedBeginTime;
        option.series[2].data = this.props.data.actualEndTime;
        option.series[3].data = this.props.data.actualBeginTime;
        option.series[0].markLine.data[0].xAxis = this.props.markLine;
        option.dataZoom[0].show = this.props.dataZoom?.show ?? true ;
        option.dataZoom[0].start = this.props.dataZoom?.start || 0;
        option.dataZoom[0].end = this.props.dataZoom?.end || 30;


        this.state.chart.setOption(option as any, true)
    }

    componentDidMount() {
        this.drawChart()
    }
    componentDidUpdate() {
        this.drawChart()
    }

    render() {
        return (
            <div id={`gantt-chart-${this.props.id}`} style={{ width: '100%', height: this.props.height }}></div>
        )
    }
}
