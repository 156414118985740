import React from 'react';
import './index.scss';

interface Props {
    data: any
}

export default function IOTCard(props: Props) {

    function handleIsUndefinedAndNull(data: any) {
        return (data === undefined || data === null);
    }

    return (
        < div className='iotCard-content-card'>
            <div className='iotCard-content-card-name'>{props.data.name}</div>
            <div className='iotCard-content-card-value' style={{ color: `${handleIsUndefinedAndNull(props.data.value) ? '#fff' : 'rgb(5, 211, 128)'}` }}>{handleIsUndefinedAndNull(props.data.value) ? '--' : props.data.value}{props.data.unit ? <span className='iotCard-content-card-unit'> {props.data.unit}</span> : <></>}</div>
        </div>
    )
}
