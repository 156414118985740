export default {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    legend: {
        x: '50',
        label: {
            fontSize: '50px',
            color: '#fff'
        },
        data: ['设备', '质量', '人员', '物流'],
        textStyle: {
            color: '#ccc',
            fontSize: '25px'
        }
    },
    toolbox: {
        feature: {
            // saveAsImage: {}
        }
    },
    grid: {
        left: '30',
        right: '20',
        top: '80',
        bottom: '',
        containLabel: true
    },
    xAxis: [
        {
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#444c59'],
                    width: 1,
                    type: 'solid'
                }
            },

            type: 'category',

            axisLabel: {
                textStyle: {
                    color: '#fff',
                    fontSize: 22
                }
            },
            axisLine: {
                lineStyle: {
                    show: false,
                    color: '#444c59'
                }
            }

        }
    ],
    yAxis: [
        {
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#444c59'],
                    width: 1,
                    type: 'solid'
                }
            },
            axisLabel: { fontSize: 22 },
            offset: 0,
            axisLine: {
                show: false,
                lineStyle: {
                    color: '#fff',
                    width: 0
                }

            },
            axisTick: {
                show: false
            },

            type: 'value',

        }
    ],
    series: [

    ]
}