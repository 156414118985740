import { Border1 } from '@/components/border'
import { LineChart } from '@/components/chart/lineChart'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { useRequest } from 'ahooks'
import React, { useState } from 'react'
import * as API from '@/api/request'
import { CenterLayout, EasyGridLayout, Layout, LineLayout } from '../../../layout'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { round } from 'lodash'
import { handleDataForLineChart, handleDataForMultipleLineChart, handleDataForMoreLineChart } from '@/utils'
import { MultipleLineChart } from '@/components/chart/multipleLineChart'
import { getTimeRangeByUrl, timeRanges } from '@/utils/time'
import _ from 'lodash'
import FormatterType from '@/components/chart/formatterType';
const pageName = 'ZS-TEK车间-设备'
export const Equipment = () => {
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())

    const { loading, error, data } = useRequest(() => {
        return Promise.all([
            API.Equipment.getlectricityValues(null, timeRange),
            API.Equipment.getUtilizationHours(null, timeRange),
            API.Equipment.getUtilizationRateByTimeRange(null, timeRange),
            API.Equipment.getElectricityQuantityInKWHByTimeRange(null, timeRange),
            API.Equipment.getALlLineElectricityQuantity(timeRange),
            API.Equipment.getAllLineUtilizationRate(timeRange)
        ])
    }, {
        refreshDeps: [timeRange]
    })
    if (loading) return <Layout name={pageName}>
        <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
        <Loading />
    </Layout>
    if (error) return <Layout name={pageName}>
        <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
        <Error />
    </Layout>
    let [electricity, utilization, utilizationRateForChart, electricityQuantityForChart, allLineQuantityForChart, allLineUtilizationRateForChart] = data
    utilizationRateForChart = utilizationRateForChart.map((item: any) => {
        return {
            ...item,
            count: round(item.count * 100, 1)
        }
    })
    let leftCard = [
        { title: '稼动率', count: round(utilization.utilizationRate * 100, 1), suffix: '%', type: 'count' },
        { title: '开机时长', count: round(utilization.usedHours, 1), type: 'time' },
        { title: '待机时长', count: round(utilization.waitingHours / 1000, 1), type: 'time', color: 'orange' },
    ]
    let rightCard = [
        { title: '能耗', count: electricity.quantityInKWH, suffix: '度', type: 'count', color: 'orange' },
        { title: '能源利用率', count: round(electricity.energyUtilizationInPairPerKWH), suffix: '双/度', type: 'count' },
        { title: '当前功率', count: round(electricity.powerInW / 1000, 1), suffix: 'kW', type: 'count' },
    ]
    return <Layout name={pageName}>
        <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }}></TimePeriodSelector>
        <LineLayout>
            <LineLayout direction='column' height={870} width={340} gap={50} margin={[0, 0, 0, 30]}>

                {
                    leftCard.map(item => {
                        return <CenterLayout border={<Border1 />}  >
                            <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.2} type={item.type} color={item.color} />
                        </CenterLayout>
                    })
                }
            </LineLayout>
            <EasyGridLayout rows={2} columns={2} width={1200} height={870} gap={20}>
                <LineChart id='equipment1' height={390} titleSize={25} data={handleDataForLineChart((timeRange as any).timeRangeType, utilizationRateForChart)} title='车间平均稼动率(%)' formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}></LineChart>
                <LineChart id='equipment2' height={390} titleSize={25} data={{
                    xAxis: handleDataForMoreLineChart((timeRange as any).timeRangeType, allLineUtilizationRateForChart).xAxis,
                    series: handleDataForMoreLineChart((timeRange as any).timeRangeType, allLineUtilizationRateForChart).series.map((item: any) => {
                        return {
                            name: item.name, data: item.data.map((element: any) => {
                                return !element ? element : _.round(element * 100, 1)
                            })
                        }
                    })
                }} title='各产线稼动率'></LineChart>
                <LineChart id='equipment3' height={390} titleSize={25} data={handleDataForLineChart((timeRange as any).timeRangeType, electricityQuantityForChart)} title='车间能耗' formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}></LineChart>
                <LineChart id='equipment4' height={390} titleSize={25} data={handleDataForMoreLineChart((timeRange as any).timeRangeType, allLineQuantityForChart)} title='各产线能耗'></LineChart>
            </EasyGridLayout>
            <LineLayout direction='column' height={850} width={300} gap={50} margin={[0, 0, 0, 30]}>
                {
                    rightCard.map(item => {
                        return <CenterLayout border={<Border1 />}   >
                            <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.2} type='count' color={item.color} />
                        </CenterLayout>
                    })
                }
            </LineLayout>

        </LineLayout>
    </Layout >
}