import React, { useState } from 'react'
import { Border1 } from '../../../components/border'
import { LineChart } from '../../../components/chart/lineChart'
import { DigitalFlop } from '../../../components/digitalFlop/digitalFlop'
import { TimePeriodSelector } from '../../../components/timePeriodSelector/timePeriodSelector'
import * as API from '@/api/request'
import { CenterLayout, EasyGridLayout, Layout, LineLayout } from '../../../layout'
import { useRequest } from 'ahooks'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { handleBigCount, handleDataForLineChart, handleDataForMoreLineChart } from '@/utils'
import _ from 'lodash'
import { BarChart } from '@/components/chart/barChart'
import { getTimeRangeByUrl } from '@/utils/time'
import FormatterType from '@/components/chart/formatterType';
const pageName = 'ZS-TEK车间-质量'
const handleDataForBarChart = (data: Array<any>) => {
    return {
        xAxis: data.map(item => item.defectName),
        yAxis: data.map(item => item.count)
    }
}

export const Quality = () => {
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())

    let hasVisionInspectionSystemRequest = useRequest(() => API.Quality.getHasVisionInspectionSystem());
    const { data, error, loading } = useRequest(() => {
        return Promise.all([
            API.Quality.getFirstPassYieldByTimeRange(null, timeRange),
            API.Quality.getFirstPassYieldByTimeRangeByLineCode(timeRange),
            API.Quality.getStatisticValues(null, timeRange),
            API.Quality.getNotGoodCountByDefectTypes(null, timeRange),
            hasVisionInspectionSystemRequest.data ? API.Quality.getNotGoodCountByTimeRange(null, timeRange) : API.Quality.getSetOnOffCountByTimeRange(null, timeRange),
        ])
    }, { refreshDeps: [timeRange], ready: !hasVisionInspectionSystemRequest.loading })
    if (hasVisionInspectionSystemRequest.loading || loading) return <Layout name={pageName}>
        <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }}></TimePeriodSelector>
        <Loading />
    </Layout>
    if (hasVisionInspectionSystemRequest.error || error) return <Layout name={pageName}><Error /></Layout>

    const [firstPassYield, allLineFirstPassYield, statisticValues, notGoodCountByDefectTypes, notGoodCount] = data
    
    const handleNotGoodCountNoVision = (data: any) => {
        let arr = [];
        notGoodCount.forEach((item: any) => {
            arr.push({
                timeRange: item.timeRange,
                count: item[data]
            })
        });
        return arr;
    }
    let rightCard = [
        { title: '直通率', suffix: '%', count: _.round(statisticValues.firstPassYield, 1) },
        { title: '良品数', ...handleBigCount(statisticValues.notGoodCount, '双') },
        { title: '缺陷次数', ...handleBigCount(statisticValues.okCount, '次') }
    ]
    let rightNoVisionCard = [
        { title: '直通率', suffix: '%', count: _.round(statisticValues.firstPassYield, 1) },
        { title: '上线数量', ...handleBigCount(statisticValues.setOnSingleCount, '只') },
        { title: '未下线', ...handleBigCount(statisticValues.unSetOffSingleCount, '只') }
    ]
    return <Layout name={pageName}>
        <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }}></TimePeriodSelector>
        <LineLayout>
            <LineLayout direction='column' height={850} width={300} gap={50} margin={[0, 0, 0, 30]}>
                {
                    hasVisionInspectionSystemRequest.data ?
                        rightCard.map(item => {
                            return <CenterLayout border={<Border1 />}  >
                                <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.4} type='count' />
                            </CenterLayout>
                        }) :
                        rightNoVisionCard.map(item => {
                            return <CenterLayout border={<Border1 />}  >
                                <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.4} type='count' />
                            </CenterLayout>
                        })
                }
            </LineLayout>
            <EasyGridLayout rows={2} columns={2} width={1500} height={850} gap={30}>
                <LineChart id='q1' height={390} data={{
                    xAxis: handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).xAxis,
                    yAxis: handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).yAxis.map((item: any) => !item ? item : _.round(item * 100, 1)),
                }} title='直通率(%)'
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}
                />
                <LineChart id='q2' height={390} data={{
                    xAxis: handleDataForMoreLineChart((timeRange as any).timeRangeType, allLineFirstPassYield).xAxis,
                    series: handleDataForMoreLineChart((timeRange as any).timeRangeType, allLineFirstPassYield).series.map((item: any) => {
                        return {
                            name: item.name, data: item.data.map((element: any) => {
                                return !element ? element : _.round(element * 100, 1)
                            })
                        }
                    })
                }} title='各产线直通率(%)' />
                {hasVisionInspectionSystemRequest ? <LineChart id='q3' height={390} data={handleDataForLineChart((timeRange as any).timeRangeType, notGoodCount)} title='缺陷次数(次)'
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}
                /> :
                    <LineChart id='q3' height={390} data={handleDataForLineChart((timeRange as any).timeRangeType, handleNotGoodCountNoVision('setOnSingleCount'))} title='上线数量(只)'
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}
                    />}
                {hasVisionInspectionSystemRequest ? <BarChart id='q4' height={390} data={handleDataForBarChart(notGoodCountByDefectTypes)} title='缺陷类型(次)' /> :
                    <LineChart id='q4' height={390} data={handleDataForLineChart((timeRange as any).timeRangeType, handleNotGoodCountNoVision('unSetOffSingleCount'))} title='未下线数量(只)'
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}
                    />}
            </EasyGridLayout>
        </LineLayout>
    </Layout>
}