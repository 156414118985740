/*
 * @Description:产线设备缩略图
 * @Author: huixu
 */
import React from 'react'
import { EasyGridLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { useRequest } from 'ahooks'
import { EnvironmentApi } from '@/api/request'
import { searchParams } from '@/utils'
import { Error } from '@/components/error'
import Loading from '@/components/loading/loading'
import _ from 'lodash'
const lineCode = searchParams().lineCode
let content = <Loading />


const OrgEnvironment = () => {
  content = <EasyGridLayout splitLine={true} rows={2} columns={2} height={370} width={360} margin={[10, 0, 0, 35]} itemPadding={[30, 0, 0, 25]}>
    <DigitalFlop title="温度" size={1.1} suffix="℃" count={23} max={35} min={0} type='count' />
    <DigitalFlop title="湿度" size={1.1} count={54} suffix="%" max={80} min={30} type='count' />
    <DigitalFlop title="噪音" size={1.1} count={69} suffix="dB" max={65} type='count' />
    <DigitalFlop title="PM2.5" size={1.1} count={33} suffix="μg/m³ " max={30} type='count' />
  </EasyGridLayout>

  return content
}
export { OrgEnvironment }
