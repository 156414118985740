import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Router from './router/index';
import { Button, ConfigProvider ,DatePicker, Space} from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Router />
  </ConfigProvider>
  ,
  document.getElementById('root'))

// document.getElementById('loading-content')!.style.display='none'
// document.getElementById('root')!.style.display='block'

serviceWorker.unregister();
