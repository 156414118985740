/*
 * @Description:基础趋势图组件
 * @Author: huixu
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import option from './barOptions.js'
import './chart.scss'

interface AxisStyle {
    fontSize?: number,
    color?: string
}
interface Props {
    id: string
    height?: string | number,
    title?: string
    margin?: string
    data?: any,
    titleSize?: number,
    axisStyle?: AxisStyle
}
interface State {
    chart: echarts.ECharts
    margin: string
}
export class BarChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            chart: null,
            margin: props.margin,
        }
    }

    drawChart() {
        if (this.state.chart) {
            this.setChart()
            return false
        }
        this.setState(
            {
                chart: echarts.init(document.getElementById(`bar-chart-${this.props.id}`) as HTMLDivElement),
            },
            () => {
                this.setChart()
            }
        )
    }

    setChart() {
        option.yAxis[0].axisLabel.fontSize = this.props.axisStyle ? this.props.axisStyle.fontSize : 22
        option.xAxis[0].data = this.props.data.xAxis
        option.series[0].data = this.props.data.yAxis
        option.xAxis[0].axisLine.fontSize = 30
        option.xAxis[0].axisLabel.textStyle.fontSize = this.props.axisStyle ? this.props.axisStyle.fontSize : 22

        this.state.chart.setOption(option as any, true)
    }

    componentDidMount() {
        this.drawChart()
    }
    componentDidUpdate() {
        this.drawChart()
    }

    render() {
        return (
            <div style={{ margin: this.state.margin, width: '100%', height: '100%' }}>
                <div className="chart-title" style={{ fontSize: this.props.titleSize + 'px' }}>
                    <div className='title-point' style={{ height: this.props.titleSize + 'px', width: this.props.titleSize + 'px' }} />

                    {this.props.title}
                </div>
                <div id={`bar-chart-${this.props.id}`} style={{ width: '100%', height: this.props.height }}>
                </div>
                {/* <div className='number'>30</div> */}

            </div>
        )
    }
}
