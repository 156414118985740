import service from '../service'

import { timeRanges } from '@/utils/time'
import moment from 'moment'
/**
 * 获取今日的呼叫次数
 */

const getTimeRange = (timeRange: any) => {
  if (!!timeRange) {
    return timeRange
  }
  return { ...timeRange.today(), timeRangeType: '0' }
}
export default {
  /**
   * 获取总产量
   * @param lineCode 
   * @returns 
   */
  getTotalCount: (lineCode?: string, lineGroupCode?: string): Promise<any> => {
    return service({
      url: '/Production/GetTotalCount',
      params: { lineCode: lineCode, lineGroupCode: lineGroupCode }
    })
  },
  /**
* 获取今日产量
* @param lineCode 
* @returns 
*/
  getTodayCount: (lineCode?: string, lineGroupCode?: string): Promise<any> => {
    return service({
      url: '/Production/GetTodayCount',
      params: { lineCode: lineCode, lineGroupCode: lineGroupCode }
    })
  },


  /**
   * 获取今日产量 根据小时返回
   */
  getTodayCountByHours: (lineCode?: string): Promise<any> => {
    return service({
      url: '/Production/GetCountByTimeRange',
      params: { lineCode, ...timeRanges.today(), timeRangeType: '0' }
    })
  },

  /**
   * /
   * 获取最近15天产量
   * @param lineCode 
   * @returns 
   */
  getCountByLast15Days: (lineCode?: string, lineGroupCode?: string): Promise<any> => {
    return service({
      url: '/Production/GetCountByTimeRange',
      params: { lineCode: lineCode, ...timeRanges.lastDays15(), timeRangeType: 2, lineGroupCode: lineGroupCode }
    })
  },

  /**
   * /
   * 获取最近30天产量
   * @param lineCode 
   * @returns 
   */
  getCountByLast30Days: (lineCode?: string): Promise<any> => {
    return service({
      url: '/Production/GetCountByTimeRange',
      params: { lineCode: lineCode, ...timeRanges.lastDays30(), timeRangeType: 2 }
    })
  },

  /**
   * 根据时间区间类型和获取产量
   * @param lineCode 产线编号
   * @param timeRange 时间区间类型
   * @returns 
   */
  getCountByTimeRange: (lineCode?: string, timeRange?: any) => {
    return service({
      url: '/Production/GetCountByTimeRange',
      params: { lineCode, ...timeRange }
    })

  },

  /**
   * 根据时段和产线获取产能
   * @param lineCode 
   * @param timeRange 
   * @returns 
   */
  getCapacityInPairPerHourByTimeRange: (lineCode?: string, timeRange?: any) => {
    return service({
      url: '/Production/GetCapacityInPairPerHourByTimeRange',
      params: { lineCode, ...timeRange }
    })
  },




  /**
   * 获取各种产量的 统计值
   * {
"count": 0,
"capacity": 0,
"capacityUnit": null,
"taktBySecondsPerPair": 0,
"shoeTypeCount": 0,
"shoeTypeAndSizeCount": 0
}
   * @param lineCode 
   * @param timeRange 
   * @returns 
   */
  getStatisticValues: (lineCode?: string, timeRange?: any) => {
    return service({
      url: '/Production/GetStatisticValues',
      params: { lineCode, ...timeRange }
    })
  },


  /**
   * 获取上线鞋形状
   * @param lineCode 
   * @param timeRange 
   * @returns 
   */
  getShoeTypeCountByTimeRange: (lineCode?: string, timeRange?: any) => {
    return service({
      url: 'Production/GetShoeTypeCountByTimeRange',
      params: { lineCode, ...timeRange }
    })
  },


  /**
   * 获取车间各个产线的产能根据时间区间
   * @param timeRange 
   * @returns 
   */
  getallLineCount: (timeRange?: any) => {
    return service({
      url: 'Production/GetCountByTimeRangeByLineCode',
      params: { ...timeRange }
    })
  },

  /**
   * 获取车间各个产线的产能
   * @param timeRange 
   * @returns 
   */
  getallLineCapacity: (timeRange?: any) => {
    return service({
      url: 'Production/GetCapacityInPairPerHourByTimeRangeByLineCode',
      params: { ...timeRange }
    })
  },



  /**
   * /获取按照鞋型的上线统计
   * @param timeRange 
   * @returns 
   */
  getCountByShoeType: (lineCode: string, timeRange?: any) => {
    return service({
      url: 'Production/GetCountByShoeType',
      params: { ...timeRange }
    })
  },

  /**
   * 获取产能数据
   */

  getCapacity: (lineCode?: string, lineGroupCode?: string) => {
    return service({
      url: 'Production/GetProductionHourStatisticsByMinutesCacl',
      params: { lineCode: lineCode, lineGroupCode: lineGroupCode }
    })
  },

  /**
   * 
   * @param lineCode 
   * @returns 获取今天的产能图表数据
   */
  getCapacityByTimeRange: (lineCode?: string, lineGroupCode?: string) => {
    return service({
      url: '/Production/GetCountByTimeRange',
      params: { lineCode: lineCode, lineGroupCode: lineGroupCode, timeRangeType: 7, beginTime: moment().format("YYYY-MM-DD") + " " + "06:30:00", endTime: moment().format("YYYY-MM-DD HH:mm:ss") }
    })
  },

  /**
   * 
   * @param lineCode 
   * @param timeRange 
   * @returns 获取按天统计的单时最高产能，时间颗粒度精细到每分钟。
   */
  getMaxCapacityPerHourInDay: (lineCode?: any, timeRange?: any) => {
    return service({
      url: '/Production/GetMaxCapacityPerHourInDay',
      params: { lineCode, ...timeRange }
    })
  },

  /**
   * 
   * @param lineCode 
   * @returns 获取按日统计的最高产量，双
   */
  getHistoricHighCountByDay: (lineCode: string) => {
    return service({
      url: 'Production/GetHistoricHighCountByDay',
      params: { lineCode }
    })
  }




}

