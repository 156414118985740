import service from '../service'
import moment from 'moment'


const today = { beginTime: moment().format('YYYY-MM-DD'), endTime: moment().add(1, 'day').format('YYYY-MM-DD') }

export default {
    /**
     * 导出摘要报表
     * @param lineCode 
     * @returns 
     */
    SummaryReport: (lineCode?: string) => {
        return service({
            responseType: 'blob',
            url: '/Export/ExportSummaryReport',
            params: { lineCode }
        })
    },
}










//Export/ExportSummaryRepor