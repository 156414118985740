import axios from 'axios'
import { requestBaseUrl } from './initEnv'
const service = axios.create({
  baseURL: requestBaseUrl,
  withCredentials: true,
  timeout: 60 * 1000 * 3,

})
// 请求拦截
service.interceptors.request.use(
  config => {

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => response.data,
  error => {

    if (error.request.status === 403) {

    }
    return Promise.reject(error)
  }
)

export default service