import React, { useState, useEffect } from 'react'
import { CenterLayout, EasyGridLayout, Layout, LineLayout } from '../../../layout'
import { Card } from '@/components/card'
import { Border1, Border3 } from '@/components/border'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { LineChart } from '@/components/chart/lineChart'
import { VideoPlayer } from '@/components/videoPlayer'
import { Quality, Equipment, } from '@/api/request'
import classNames from 'classnames'
import Icon from '@/components/icon/icon'
import useRequest from '@ahooksjs/use-request'
import { handleDataForLineChart, searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import './index.scss'
import _ from 'lodash'
import { store } from '@/store'
import { Empty } from '@/components/empty'
import { Camera } from '@babylonjs/core'
import FormatterType from '@/components/chart/formatterType';
import { requestBaseUrl } from '@/api/initEnv'

//可空的工位编码；1~9：前帮打磨、帮面打磨左、帮面打磨右、涂处理剂左、涂处理剂右、涂胶左、涂胶右、喷底处理剂、喷底胶。

export const WorkStation = () => {

    const lineCode = useState(searchParams().lineCode)[0]
    const workStationCode = useState(searchParams().workStationCode)[0]
    const [timeRange, setTimeRange] = useState({})
    const [leftLoading, setLeftLoading] = useState(true)
    const [qualityCard, setQualityCard] = useState(
        [
            { count: 100, suffix: '%', title: '直通率' },
            { count: 300, suffix: '次', title: '加工成功' },
            { count: 300, suffix: '次', title: workStationCode === '1' || workStationCode === '2' || workStationCode === '3' ? '打磨面积过小' : '溢胶' },
            { count: 300, suffix: '次', title: workStationCode === '1' || workStationCode === '2' || workStationCode === '3' ? '打磨面积过大' : '断胶' },
        ]
    )
    const [firstPassYield, setFirstPassYield] = useState([])
    const [shoeTest, setShoeTest] = useState({
        shoeTypeCodeInRFID: undefined,
        sizeForHuman: undefined,
        posizione: '',
        result: '',
        qualityCheckRecordId: undefined,
    });
    const [testPicture, setTestPicture] = useState([]);
    const [robotCurrentStatus, setRobotCurrentStatus] = useState([]);
    const robotStatus = [
        { icon: [require('../../../assets/images/workStation/sk10.png')], value: [] },
        { icon: [require('../../../assets/images/workStation/sk11.png')], value: ['总计：'] },
        { icon: [require('../../../assets/images/workStation/sk1.png')], value: ['初始化', '伺服关闭', '伺服开启', '戒备状态', '紧急停止', '急停恢复', '系统失效', '未知'] },
        { icon: [require('../../../assets/images/workStation/sk2.png')], value: ['自动', '手动减速', '手动全速', '自动全速准备', '手动全速准备', '未定义状态'] },
        { icon: [require('../../../assets/images/workStation/sk9.png')], value: ['速度: '] },
        { icon: [require('../../../assets/images/workStation/sk4err.png'), require('../../../assets/images/workStation/sk4.png')], value: ['不在原点', '到达原点'] },
        { icon: [require('../../../assets/images/workStation/sk5.png'), require('../../../assets/images/workStation/sk5err.png')], value: ['执行正常', '执行异常'] },
        { icon: [require('../../../assets/images/workStation/sk6.png'), require('../../../assets/images/workStation/sk6err.png')], value: ['温度正常', '温度异常'] },
        { icon: [require('../../../assets/images/workStation/sk7.png'), require('../../../assets/images/workStation/sk7err.png')], value: ['风扇正常', '风扇异常'] },
        { icon: [require('../../../assets/images/workStation/sk8.png'), require('../../../assets/images/workStation/sk8err.png')], value: ['电池正常', '电池异常'] },
    ];

    useRequest(() => {
        return Promise.all([
            Quality.getLastQualityCheckRecord(lineCode, workStationCode),
            Equipment.getRobotCurrentStatusByLocation(lineCode, workStationCode),
        ])
    }, {
        onSuccess: (data) => {
            setShoeTest({
                sizeForHuman: data[0].sizeForHuman,
                shoeTypeCodeInRFID: data[0].shoeTypeNameForHuman,
                posizione: data[0].isRightOnly === true ? '右脚' : data[0].isRightOnly === false ? '左脚' : '双脚',
                result: data[0].isNotGood ? 'NG' : 'OK',
                qualityCheckRecordId: data[0].id,
            });
            let shoeInformation = (data[1].shoeTypeNameForHuman || '') + '-' + (data[1].shoeSizeForHuman || '') + '-' + (data[1].shoeIsRight === undefined || data[1].shoeIsRight === null ? '' : data[1].shoeIsRight ? '右' : '左');
            setRobotCurrentStatus([shoeInformation, parseFloat(data[1].shoesCount || 0).toLocaleString(), data[1].controllerOperationMode, data[1].controllerState, data[1].speedRatio || 0, data[1].onHomePosition, data[1].hasExecutionError, data[1].temperatureIsWarning, data[1].cpuFanIsStop, data[1].batteryIsLow])
        },
        pollingInterval: 5000,
    })

    useEffect(() => {
        Quality.getQualityCheckPictureRelativeUrls(shoeTest.qualityCheckRecordId).then((res: any) => {
            setTestPicture(res);
        })
    }, [shoeTest])

    useRequest(() => {
        return Promise.all([
            Quality.getStatisticValues(lineCode, timeRange, workStationCode),
            Quality.getNotGoodCountByDefectTypes(lineCode, timeRange, workStationCode),
            Quality.getFirstPassYieldByTimeRange(lineCode, timeRange, workStationCode),
        ])
    }, {
        onSuccess: (data) => {
            let cards = qualityCard
            cards[0].count = _.round(data[0].firstPassYield * 100, 1)
            cards[1].count = data[0].okCount
            if (workStationCode === '1' || workStationCode === '2' || workStationCode === '3') {
                cards[2].count = data[1][1].count;
                cards[3].count = data[1][3].count;
            } else {
                cards[2].count = data[1][0].count;
                cards[3].count = data[1][2].count;
            }
            setFirstPassYield(data[2])
            setQualityCard(cards)
            setLeftLoading(false)
        },
        refreshDeps: [timeRange],
        pollingInterval: 5000,
    })

    const onChangeTimeRange = (timeRange: any) => {
        setLeftLoading(true)
        setTimeRange(timeRange)
    }

    let pageName = ''
    let camera = store.getState().cameras.find((item: any) => item.id === parseInt(searchParams().cameraId))
    if (!camera) {
        camera = store.getState().cameras.find((item: any) => item.workStationCode === workStationCode)
    }
    const robot = store.getState().robots.find((item: any) => item.workStationCode === workStationCode)
    if (robot) {
        pageName = robot.name
    } else {
        pageName = camera.name
    }

    // console.log('********************')

    // console.log(camera)
    // console.log('********************')

    if (workStationCode === '101' || workStationCode === '102' || workStationCode === '103' || !workStationCode) {
        return (
            <Layout name={pageName}>
                {/* 视频直播和机器人状态 */}
                <VideoPlayer
                    width={1850}
                    height={970}
                    url={camera.yS7UrlFluency}
                    token={camera.yS7AccessToken}
                    margin='20px 0 0 0'
                />
            </Layout>
        )
    }

    return <Layout name={pageName}>
        <LineLayout margin={[30, 0, 0, 0]}>
            {/* 加工数据 */}
            <Card border={<Border1 />} width={720} height={950} title='质量' >
                <TimePeriodSelector change={(timeRange: any) => onChangeTimeRange(timeRange)} />
                {
                    !leftLoading ?
                        <React.Fragment>
                            <EasyGridLayout rows={2} columns={2} width={660} height={360} gap={30} margin={[0, 30, 50, 30]} >
                                {
                                    qualityCard.map((item: any, index: number) => {
                                        return <CenterLayout border={<Border3 />} key={index}>
                                            <DigitalFlop count={item.count} size={1.2} type='count' suffix={item.suffix} title={item.title} />
                                        </CenterLayout>
                                    })
                                }
                            </EasyGridLayout>
                            <LineChart id='station1' data={{
                                xAxis: handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).xAxis,
                                yAxis: handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).yAxis.map((item: any) => {
                                    return !item ? item : _.round(item * 100, 1)
                                })
                            }}
                                title='直通率(%)' height={300} titleSize={26} warpHeight={0}
                                formatter={(timeRange as any).timeRangeType === 0 ? FormatterType.todayMoment : null}
                            />
                        </React.Fragment> : <Loading />
                }
            </Card>
            {/* 视频直播和机器人状态 */}
            <LineLayout width={400} direction='column' height={950} justify='start' gap={30}>
                <Card border={<Border1 />} height={350} title='视频直播' >

                    {
                        camera ? <VideoPlayer
                            width={390}
                            height={220}
                            url={camera.yS7UrlFluency}
                            token={camera.yS7AccessToken}
                            margin='0px 0px 10px 5px' /> : <Empty />
                    }

                </Card>

                <Card border={<Border1 />} title='设备状态'  >
                    <EasyGridLayout rows={4} columns={2} height={410} width={370} gap={10} margin={[20, 0, 0, 20]}>
                        {
                            robotStatus.map((item: any, index: number) => {
                                return <LineLayout justify='start' gap={10} key={index}>
                                    {
                                        index < 5 ?
                                            <Icon url={item.icon[0]} size={1.9} />
                                            :
                                            <Icon url={robotCurrentStatus[index] ? item.icon[1] : item.icon[0]} size={2} />
                                    }
                                    {
                                        index === 0 ?
                                            <span className='robot-status-value'>{robotCurrentStatus[index]}</span>
                                            :
                                            index > 1 && index < 4 ?
                                                <span className='robot-status-value'>{item.value[Number(robotCurrentStatus[index])]}</span>
                                                :
                                                index === 1 || index === 4 ?
                                                    <span className='robot-status-value'>{`${item.value[0]}${(robotCurrentStatus[index])}`}</span>
                                                    :
                                                    <span className='robot-status-value'>{robotCurrentStatus[index] ? item.value[1] : item.value[0]}</span>
                                    }
                                </LineLayout>
                            })
                        }
                    </EasyGridLayout>
                </Card>
            </LineLayout>
            {/* 右侧视觉检测结果 */}
            <Card height={950} width={650} border={<Border1 />} title='实时品质检测'>
                {workStationCode === '8' || workStationCode === '9' ? <Empty tip={'暂无数据！'} />
                    :
                    <>
                        <div className='check-item'>
                            <div>鞋型</div>
                            <div className='check-item-type'>{shoeTest.shoeTypeCodeInRFID}</div>
                            <div>{shoeTest.sizeForHuman}码</div>
                            <div>{shoeTest.posizione}</div>
                            <div className={classNames({
                                'check-result': true,
                                'ok': true,
                                'ng': shoeTest.result === 'NG',
                            })}>{shoeTest.result}</div>
                        </div>
                        {/* <div className='check-item'>
                            <div>尺码</div>
                            <div className='check-item-size'>36.0</div>
                        </div> */}
                        {/* <div className='check-item'>
                            <div>检测结果</div>
                        </div> */}
                        <div className='check-img'>
                            <img src={`${requestBaseUrl}/${testPicture[0]}`} width='auto' height='370px' style={{ marginTop: '20px' }} />
                            <img src={`${requestBaseUrl}/${testPicture[1]}`} width='auto' height='370px' style={{ marginTop: '20px' }} />
                        </div>
                    </>
                }
            </Card>
        </LineLayout>

    </Layout>
}
